import { Chart } from 'react-chartjs-2';

function MonthlyConsumption(props) {
  const energy = (
    <Chart
      type="bar"
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            type: 'linear',
            title: {
              display: true,
              text: 'Historical Bill'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: () => '',
              title(items) {
                return items.map((i) => `${i.label}: ${i.formattedValue} kWh`);
              }
            }
          }
        }
      }}
      data={{
        datasets: [
          {
            label: 'kWh',
            data: props.kwh
          }
        ]
      }}
    />
  );

  const cost = (
    <Chart
      type="bar"
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            type: 'linear',
            ticks: {
              format: {
                style: 'currency',
                currency: 'USD'
              }
            },
            title: {
              display: true,
              text: 'Historical Bill'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: () => '',
              title(items) {
                return items.map((i) => `${i.label}: ${i.formattedValue}`);
              }
            }
          }
        }
      }}
      data={{
        datasets: [
          {
            label: 'Cost',
            data: props.cost
          }
        ]
      }}
    />
  );

  switch (props.mode) {
    case 'energy':
      return energy;
    case 'cost':
      return cost;
    default:
      return energy;
  }
}

export default MonthlyConsumption;
