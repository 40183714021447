import React, { useContext } from 'react';
import styles from './Modal.module.scss';
import { MdArrowForward } from 'react-icons/md';
import Button from '../Button/Button';
import CloseCircularIcon from '../../resources/icons/CloseCircularIcon';
import { ModalContext } from '../../provider/modal.provider';

const Modal = () => {
  const {
    isModalOpen,
    toggleModal,
    modalParams,
    isBackdropClickDisable,
    isCloseButton,
    closeModal
  } = useContext(ModalContext);

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    !isBackdropClickDisable && toggleModal();
  };

  return (
    <div
      className={`${styles.globalModalWrapper} ${isModalOpen ? styles.open : ''}`}
      onClick={handleBackdropClick}>
      <div className={styles.globalModal}>
        <div className={styles.head}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{modalParams.title}</span>
          </div>
          {isCloseButton && (
            <button onClick={toggleModal}>
              <CloseCircularIcon />
            </button>
          )}
        </div>
        <div className={`${styles.content} ${modalParams.contentClassName}`}>
          {modalParams.content}

          {modalParams.buttons && (
            <div className="flex justify-center mt-4 space-x-2 mb-[24px]">
              {modalParams.buttons.map((button, index) => (
                <Button
                  key={index}
                  onClick={async () => {
                    button.action && button.action();
                    closeModal();
                  }}
                  size="large"
                  className="!py-[18px] font-aeonik-bold"
                  theme="primary">
                  <p className="!min-w-[132px]">{button.text}</p>
                </Button>
              ))}
            </div>
          )}
        </div>
        {/* <div className="footer">
          <Button
            onClick={() => onNext()}
            size="large"
            icon={
              hasNext && (
                <MdArrowForward
                  size={24}
                  className="!fill-[#ffffff] absolute right-[24px] top-[15px]"
                />
              )
            }
            className="!py-[18px]"
            iconPosition="right"
            theme="primary">
            <p className="!min-w-[132px]">{hasNext ? ' Next Step' : 'Done'}</p>
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default Modal;
