import React, { useContext } from 'react';
import crossDiskShape from '../../../assets/images/cross_disk_shape.png';
import panelImg from '../../../assets/images/Group_41465.png';
import bulbImg from '../../../assets/images/Group_41469.png';
import homeImg from '../../../assets/images/Group_41696.png';
import solarCostImg from '../../../assets/images/Group_41603.png';
import BlogCard from '../../Card/BlogCard/BlogCard';
import { SolarDataContext } from '../../../provider/solarData.provider';

const Step6 = () => {
  const { solarInquiryData } = useContext(SolarDataContext);

  const generateDescriptionHTML = () => {
    if (solarInquiryData && solarInquiryData.customerAddress) {
      if (solarInquiryData.customerAddress.state === 'NJ') {
        return `
          <p>There is a federal 30% tax credit on the hardware and installation cost of solar panels and batteries.</p>
          <p class="mt-2 text-[16px] text-primary font-aeonik-bold leading-[24px] text-left mb-[8px]">
            As a New Jersey resident, you'll also benefit from very significant state-specific incentives called SRECs.
          </p>
          <p>You will be eligible to receive 8.5 cents per kWh produced for the first 15 years of the system's life.</p>
        `;
      } else if (solarInquiryData.customerAddress.state === 'PA') {
        return `
          <p>There is a federal 30% tax credit on the hardware and installation cost of solar panels and batteries.</p>
          <p class="mt-2 text-[16px] text-primary font-aeonik-bold leading-[24px] text-left mb-[8px]">
          As a Pennsylvania resident, you'll also benefit from very significant state specific incentives called SRECs.
          </p>
          <p>You will be eligible to receive payments for each kWh produced.</p>
        `;
      }
    }

    return '<p>There is a federal 30% tax credit on the hardware and installation cost of solar panels and batteries.</p>';
  };

  const descriptionHTMLForInflationRate = generateDescriptionHTML();

  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={crossDiskShape} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div className="relative">
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">Why now?</p>

        <div className="grid lg:grid-cols-4 lg:gap-4 md:grid-cols-2 md:gap-2 grid-cols-1 gap-2 sm:mb-0 mb-[72px]">
          <BlogCard
            title={'Electricity rates are rising rapidly.'}
            description={
              'Since 2021, the average retail electricity rate in the U.S. has increased by 7.5%. This is due to economy-wide inflation, rapid increases in electricity demand from new sources like transportation, and increasing regulatory costs on electric companies.'
            }
            className={'bg-secondary xl:[&>*:first-child]:mx-[92px]'}
            imageProp={{ image: bulbImg, className: 'w-[138px] h-[138px]' }}
          />
          <BlogCard
            title={
              'The Inflation Reduction Act of 2022 offers significant incentives for solar and batteries.'
            }
            description={descriptionHTMLForInflationRate}
            className={'bg-secondary xl:[&>*:first-child]:mx-[92px]'}
            imageProp={{ image: homeImg, className: 'w-[175px] h-[175px]' }}
          />
          <BlogCard
            title={'Solar panel prices are at an all-time low.'}
            description={
              "The cost of solar panels has been declining steadily for years, and it's now more affordable than ever to go solar."
            }
            className={'bg-secondary xl:[&>*:first-child]:mx-[92px]'}
            imageProp={{ image: panelImg, className: 'w-[181px] h-[142px]' }}
          />
          <BlogCard
            title={'Increase in home value.'}
            description={
              'In today’s home market, solar can increase the value and attractiveness of your home. On average, solar systems add an immediate 4% increase in the value of a home.'
            }
            className={'bg-secondary xl:[&>*:first-child]:mx-[92px]'}
            imageProp={{ image: solarCostImg, className: 'w-[167px] h-[144px]' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Step6;
