import React from 'react';
import './Loader.scss';
import logo from '../../assets/gifs/Logo.gif';

const Loader = ({ showLoaderOnly = false }) => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="text-center flex-col justify-center">
        <img src={logo} alt="loader" className="m-auto" />
        {!showLoaderOnly && (
          <>
            <h2 className="text-primary text-lg lg:text-[35px] font-aeonik-bold">Hold tight!</h2>
            <p className="text-primary text-xs lg:text-[16px] leading-1 lg:leading-[20px] m-auto max-w-[65%] lg:py-10 py-4">
              We’re just making sure all your information is correct. This might take some time.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Loader;
