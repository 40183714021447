import React from 'react';

const WrenchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_2817_30597)">
        <path
          d="M21.94 4.75996L18.12 8.57996L16.12 7.89996L15.44 5.89996L19.26 2.07996C18.2606 1.60125 17.1436 1.42368 16.045 1.5689C14.9464 1.71412 13.9139 2.17584 13.0732 2.89777C12.2326 3.61971 11.6201 4.57065 11.3106 5.63465C11.001 6.69865 11.0078 7.82973 11.33 8.88996L1.5 18.68L5.32 22.5L15.13 12.69C16.1902 13.0122 17.3213 13.0189 18.3853 12.7094C19.4493 12.3998 20.4002 11.7874 21.1222 10.9467C21.8441 10.1061 22.3058 9.0735 22.4511 7.97495C22.5963 6.8764 22.4187 5.75933 21.94 4.75996Z"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2817_30597">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WrenchIcon;
