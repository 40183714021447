import { Chart } from 'react-chartjs-2';
import { useEffect, useMemo, useRef } from 'react';

function ProgressiveChart(props) {
  const childProps = useMemo(() => {
    const values = props.data.datasets.flatMap((s) => s.data).map((v) => v.y);

    return {
      ...props,
      options: {
        ...props.options,
        animation: props.options?.animation
          ? {
              ...props.options?.animation,
              duration: undefined
            }
          : props.options?.animation,
        scales: {
          ...props.options?.scales,
          y: {
            ...props.options?.scales?.y,
            suggestedMin: Math.min(...values),
            suggestedMax: Math.max(...values)
          }
        }
      },
      data: {
        ...props.data,
        datasets: [
          ...props.data.datasets.map((s) => ({
            ...s,
            data: [],
            animation: s.animation
              ? {
                  ...s.animation,
                  easing: 'linear',
                  duration: (() => {
                    if (typeof s.animation.duration === 'number') {
                      return s.animation.duration / s.data.length;
                    }
                  })()
                }
              : {
                  easing: 'linear',
                  duration: (() => {
                    return 2000 / s.data.length;
                  })()
                }
          }))
        ]
      }
    };
  }, [props]);
  const chartRef = useRef();

  useEffect(() => {
    const chart = chartRef.current;

    let dataset = 0;
    const timeouts = [];

    const getDuration = (s) => s.animation.duration;

    function next() {
      const current = chart.data.datasets[dataset].data;
      const target = props.data.datasets[dataset].data;

      if (current.length === target.length) {
        if (++dataset < props.data.datasets.length) {
          timeouts.push(setTimeout(next, getDuration(chart.data.datasets[dataset])));
        }
        return;
      }

      const addition = current[current.length - 1] ?? target[0];

      // Avoid exception when updating when canvas is null
      if (chart.canvas !== null) {
        current.push(addition);
        chart.update('none');
        current[current.length - 1] = target[current.length - 1];
        chart.update();
      }

      timeouts.push(setTimeout(next, getDuration(chart.data.datasets[dataset])));
    }

    next();

    return () => {
      for (const timeout of timeouts) {
        clearTimeout(timeout);
      }
    };
  }, [props.data.datasets]);

  return <Chart {...childProps} ref={chartRef} />;
}

export default ProgressiveChart;
