import React, { useContext, useEffect } from 'react';
import daylightSortLogo from '../../../assets/images/daylight-logo-blue.png';
import homeImg from '../../../assets/images/step_1_home_img.png';
import styles from './Steps.module.scss';
import DaylightLogo from '../../../resources/icons/DaylightLogo';
import { SolarDataContext } from '../../../provider/solarData.provider';

const Step1 = () => {
  const { solarInquiryData } = useContext(SolarDataContext);

  return (
    <div className="sm:pt-[86px] pt-[10px] pb-[100px]">
      <div className="flex sm:flex-row flex-col-reverse">
        {/* <img src={TestImage} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" /> */}
        <div className="sm:w-[60%] w-full sm:text-[80px] text-[54px] text-primary md:leading-[120%] sm:leading-[60px] leading-[54px] flex justify-start items-end">
          <div className="sm:text-[54px] md:text-[80px] text-[54px] ">
            {/* Daylight
            <span className={`${styles.step1DaylightLogo} sm:w-full w-[20%]`}>
              <img src={daylightSortLogo} alt="" />
            </span> */}
            <span>
              <DaylightLogo />
            </span>
            <div className="my-[25px]">Home Power System</div>
          </div>
        </div>
        <div className="sm:w-[40%] w-full">
          <img src={homeImg} alt="" className="sm:w-[85%] w-full mb-[15px] text-left" />
        </div>
      </div>
      <div className=" border-primary border-t-2 border-solid sm:mt-[15px] mt-0">
        <div className="flex sm:flex-row flex-col gap-4 sm:mt-[40px] mt-[25px]">
          <div className="sm:mr-[24px] mr-0 sm:mb-0 mb-[72px]">
            <p className="text-primary sm:text-[22px] text-[18px]">
              {solarInquiryData && solarInquiryData.customerAddress ? (
                <>
                  &ldquo;For{' '}
                  {solarInquiryData && solarInquiryData.customerAddress
                    ? solarInquiryData.customerAddress.address +
                      `,
                ` +
                      solarInquiryData.customerAddress.city +
                      `,
                ` +
                      solarInquiryData.customerAddress.state +
                      ` ` +
                      solarInquiryData.customerAddress.zipCode
                    : ' '}
                  &rdquo;
                </>
              ) : (
                ``
              )}
            </p>
          </div>
          {/* <div className="sm:mr-[24px] mr-0">
            <p className="text-primary sm:text-[22px] text-[18px]">
              Custom design for [
              {solarInquiryData && solarInquiryData.customerName
                ? solarInquiryData.customerName
                : '---'}
              ]
            </p>
          </div> */}
          {/* <div className="sm:ml-[24px] ml-0">
            <p className="text-primary sm:text-[22px] text-[18px]">
              Created by [
              {solarInquiryData && solarInquiryData.createdBy ? solarInquiryData.createdBy : '---'}]
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Step1;
