import React from 'react';
import styles from './HamburgerButton.module.scss';

const HamburgerButton = ({ isOpen, toggleSidebar }) => {
  return (
    <div
      className={`${styles.hamburgerButton} ${isOpen ? styles.open : ''}`}
      onClick={toggleSidebar}>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </div>
  );
};

export default HamburgerButton;
