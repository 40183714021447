import React, { useContext, useEffect, useRef, useState } from 'react';
import { StepperContext } from '../../../../provider/stepper.provider';
import { AnimatePresence, motion, transform } from 'framer-motion';

const variants = {
  enter: (direction) => ({
    x: direction === 'back' ? '-100vw' : '100vw'
  }),
  center: {
    x: 0,
    transitionEnd: {
      x: 0,
      position: 'relative'
    }
  },
  exit: (direction) => ({
    x: direction === 'back' ? '100vw' : '-100vw'
  })
};

const StepperContent = () => {
  const { stepsArray, activeStep, prevActiveStep } = useContext(StepperContext);

  const direction = activeStep >= prevActiveStep ? 'next' : 'back';

  return (
    <AnimatePresence initial={false} mode="popLayout" custom={direction}>
      <motion.div
        key={activeStep}
        custom={direction}
        variants={variants}
        transition={{
          ease: 'easeInOut',
          duration: 0.6
          // x: { duration: 0.5 }
        }}
        initial="enter"
        animate="center"
        exit="exit">
        {stepsArray[activeStep]}
      </motion.div>
    </AnimatePresence>
  );
};

export default StepperContent;
