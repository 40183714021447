import React, { useContext } from 'react';
import StepperHeader from './components/StepperHeader/StepperHeader';
import StepperProgress from './components/StepperProgress/StepperProgress';
import StepperFooter from './components/StepperFooter/StepperFooter';
import StepperContent from './components/StepperContent/StepperContent';
import { StepperContext } from '../../provider/stepper.provider';

const Stepper = () => {
  const { stepsArray, activeStep, setActiveStep } = useContext(StepperContext);

  const blueBgStepsArray = [0, 1, 4, 5, 6];

  const checkStepBlongsToBlueBgStepsArray = () => {
    return blueBgStepsArray.includes(activeStep);
  };
  // lg:h-[80vh]
  return (
    <div
      className={`px-[40px] py-[16]  h-full ${
        checkStepBlongsToBlueBgStepsArray() ? 'bg-quaternary' : 'bg-white'
      }`}>
      <div
        className={`z-50 fixed top-0 left-0 right-0 px-[40px] h-[90px]  py-[16px] ${
          checkStepBlongsToBlueBgStepsArray() ? 'bg-quaternary' : 'bg-white'
        }`}>
        <StepperHeader />
      </div>
      {activeStep !== 0 && (
        <div className="z-10 fixed top-[90px] left-0 right-0">
          <StepperProgress />
        </div>
      )}

      <div className={`${activeStep !== 0 ? 'mt-[95px]' : 'mt-[90px]'} min-h-[calc(100vh-105px)]`}>
        <StepperContent />
      </div>
      <div
        className={`fixed bottom-0 left-0 right-0 px-[40px] h-[88px] py-[16px] border-t-2 border-solid border-[#E6E6E6]] z-10 ${
          checkStepBlongsToBlueBgStepsArray() ? 'bg-quaternary' : 'bg-white'
        }`}>
        <StepperFooter />
      </div>
    </div>
  );
};

export default Stepper;
