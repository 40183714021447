import React from 'react';

const NetMeteringIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_2817_30551)">
        <path
          d="M20.59 1.5H3.41C2.35514 1.5 1.5 2.35514 1.5 3.41V20.59C1.5 21.6449 2.35514 22.5 3.41 22.5H20.59C21.6449 22.5 22.5 21.6449 22.5 20.59V3.41C22.5 2.35514 21.6449 1.5 20.59 1.5Z"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M17.72 6.26953H6.27002V17.7195H17.72V6.26953Z"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M8.18018 4.36035V6.27035"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M8.18018 19.6405V17.7305"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path d="M12 4.36035V6.27035" stroke="#15049E" strokeWidth="1.91" strokeMiterlimit="10" />
        <path d="M12 19.6405V17.7305" stroke="#020202" strokeWidth="1.91" strokeMiterlimit="10" />
        <path
          d="M15.8198 4.36035V6.27035"
          stroke="#020202"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M15.8198 19.6405V17.7305"
          stroke="#020202"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M4.35986 15.8203H6.26986"
          stroke="#020202"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path d="M19.64 15.8203H17.73" stroke="#020202" strokeWidth="1.91" strokeMiterlimit="10" />
        <path d="M4.36011 12H6.27011" stroke="#020202" strokeWidth="1.91" strokeMiterlimit="10" />
        <path d="M19.64 12H17.73" stroke="#020202" strokeWidth="1.91" strokeMiterlimit="10" />
        <path
          d="M4.35986 8.17969H6.26986"
          stroke="#020202"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path d="M19.64 8.17969H17.73" stroke="#020202" strokeWidth="1.91" strokeMiterlimit="10" />
        <path d="M12.0001 12.9597H9.14014L12.0001 8.17969V12.9597Z" fill="#020202" />
        <path d="M12 11.04H14.86L12 15.82V11.04Z" fill="#020202" />
      </g>
      <defs>
        <clipPath id="clip0_2817_30551">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NetMeteringIcon;
