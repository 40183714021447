import React from 'react';
import Stepper from '../../components/Stepper/Stepper';

const Home = () => {
  return (
    <div>
      <Stepper />
    </div>
  );
};

export default Home;
