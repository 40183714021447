import React from 'react';
import styles from './TimelineCard.module.scss';
import MaximizeIcon from '../../../../resources/icons/MaximizeIcon';

const TimelineCard = ({ image, title }) => {
  return (
    <div className={`${styles.timelineCard} border-[1px] border-solid border-primary `}>
      <div className="relative max-h-[90px]">
        <img src={image} className={styles.timelineCardImg} width={'220px'} alt="" />
        <div className="absolute top-[5px] right-[6px]">
          <MaximizeIcon />
        </div>
      </div>
      <div className="border-t-[1px] border-primary border-solid py-[8px]">
        <h4 className="text-center text-primary font-aeonik-bold text-[12px] leading-[16px] tracking-[0.1px]">
          {title}
        </h4>
      </div>
    </div>
  );
};

export default TimelineCard;
