import React, { useContext, useEffect, useState } from 'react';
import triangleImage from '../../../assets/images/triangle_shape.png';
import MonthlyConsumption from '../../Charts/MonthlyConsumption';
import consumption from './consumption.json';
import { ButtonSelect } from '../../ButtonSelect/ButtonSelect';
import { ModalContext } from '../../../provider/modal.provider';
import IdeaIcon from '../../../resources/icons/IdeaIcon';
import Button from '../../Button/Button';
import { SolarDataContext } from '../../../provider/solarData.provider';
import { UtilityContext } from '../../../provider/utility.provider';
import { formatDate } from '../../../utils/utils';
import { UtilityStatusType } from '../../../GraphQL/Types';
import { useMutation } from '@apollo/client';
import { CREATE_NONCE_TOKEN_MUTATION, REQUEST_AUTH_CHALLENGE } from '../../../GraphQL/Mutations';

const UTILITY_URL = process.env.REACT_APP_UTILITY_URL;

const months = (() => {
  let result = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const shorten = (v) => v.toString().slice(2);
  result = result.map((m, i) =>
    i >= currentMonth ? `${m}-${shorten(currentYear - 1)}` : `${m}-${shorten(currentYear)}`
  );
  for (let i = 0; i < currentMonth; ++i) {
    result.push(result.shift());
  }
  return result;
})();

const consumptionData = {
  kwh: consumption.avg_kwh.map((v, i) => ({
    x: months[i],
    y: v
  })),
  cost: consumption.avg_bill.map((v, i) => ({
    x: months[i],
    y: v
  }))
};

const dummyData = [
  {
    charges: '111.00',
    usage: '11.30',
    endDate: '2023-08-11'
  },
  {
    charges: '222.00',
    usage: '22.30',
    endDate: '2023-07-11'
  },
  {
    charges: '333.00',
    usage: '33.30',
    endDate: '2023-06-11'
  },
  {
    charges: '444.00',
    usage: '44.30',
    endDate: '2023-05-11'
  },
  {
    charges: '111.00',
    usage: '11.30',
    endDate: '2023-04-11'
  },
  {
    charges: '222.00',
    usage: '22.30',
    endDate: '2023-03-11'
  }
];

function formatDataForChart(data) {
  const formattedData = {
    kwh: data.map((v) => ({
      x: formatDate(v.endDate, 'MMM-YY'),
      y: parseFloat(Number(v.usage).toFixed(2))
    })),
    cost: data.map((v) => ({
      x: formatDate(v.endDate, 'MMM-YY'),
      y: parseFloat(Number(v.charges).toFixed(2))
    }))
  };

  return formattedData;
}

// console.log('dummyData :', formatDataForChart(dummyData));

const Step3 = () => {
  // let popup;
  const [mode, setMode] = useState('energy');
  const { openModal, setModalParams } = useContext(ModalContext);
  const { solarInquiryData, authToken } = useContext(SolarDataContext);
  const { monthlyAverageElectricityCostData, utilityMonthlyData, urjanet } =
    useContext(UtilityContext);
  const [getNonceTokenMutation] = useMutation(CREATE_NONCE_TOKEN_MUTATION);

  const getNonceToken = async () => {
    try {
      const nonceRes = await getNonceTokenMutation({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });
      if (!nonceRes) throw new Error('Error found');
      // console.log('leadId :', createLeadRes);
      // setLeadId(createLeadRes.data.createLead.lead.id);
      return nonceRes.data.createNounceToken.nounceToken.nounce;
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   console.log('authToken :', authToken);
  // }, [authToken]);

  // useEffect(() => {
  //   const authdd = await getNonceToken();
  //   console.log('fff :', authdd);
  // }, []);

  const triggerModal = async () => {
    const redirectToUtilityWithNonce = async () => {
      try {
        const nonceToken = await getNonceToken();
        const UTILITY_URL_WITH_AUTH_PARAM = `${UTILITY_URL}?authToken=${encodeURIComponent(
          nonceToken
        )}`;
        window.open(UTILITY_URL_WITH_AUTH_PARAM, '_blank');
      } catch (error) {}
    };

    openModal();
    setModalParams({
      contentClassName: '!border-none !p-0',
      content: (
        <div>
          <span className="flex justify-center items-center">
            <IdeaIcon />
          </span>
          <h4 className="px-[40px] py-[20px] font-aeonik-bold text-[24px] leading-[24px] flex justify-center items-center">
            The best things in life are personalized.
          </h4>

          <p className="text-[16px] leading-[24px] text-primary py-[15px] px-[80px] flex justify-center items-center">
            Connect your utility account to unlock your free, custom solar design and savings.
          </p>
        </div>
      ),
      buttons: [
        {
          text: 'Connect Now',
          action: () => {
            // console.log('Ddddd-dd');
            // window.open(UTILITY_URL + `?authToken=` + authToken, '_blank');
            // window.open(UTILITY_URL, '_blank');
            // window.open(UTILITY_URL_WITH_AUTH_PARAM, '_blank');
            // openUtilityWindow();
            redirectToUtilityWithNonce();
          }
        }
      ]
    });
  };

  const openPopupCenter = (url, title, w, h) => {
    // Calculate the position for the popup window to be centered on the screen
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;

    // Open the popup window
    const popup = window.open(url, title, `width=${w},height=${h},left=${left},top=${top}`);

    // // Add an event listener to detect when the user closes the popup
    // popup.addEventListener('beforeunload', (event) => {
    //   // Inform the main window that the popup is being closed
    //   window.opener.postMessage('popupClosed', '*');
    // });

    // Focus the popup (optional)
    if (window.focus) {
      popup.focus();
    }
    // console.log('popup :', popup.closed);

    // // Check if the popup is closed at regular intervals
    // const checkPopupClosed = setInterval(() => {
    //   console.log('closed :', popup.closed);
    //   console.log('popup :', popup);
    //   if (popup === null || popup.closed) {
    //     clearInterval(checkPopupClosed); // Stop checking when closed
    //     // Handle the closed popup here
    //     handlePopupClosed();
    //   }
    // }, 1000);

    window.addEventListener('message', handleMessage);

    function handleMessage(event) {
      console.log('event :', event);
      // Ensure the message is from the popup and not from a malicious source
      if (event.source === popup) {
        // Handle the message sent from the popup
        if (event.data === 'popupClosed') {
          // Popup has been closed by the user
          handlePopupClosed();
        }
      }
    }

    // Add a beforeunload event listener to show an error message when the user tries to close the popup
    popup.addEventListener('beforeunload', (event) => {
      // Customize the error message here
      console.log('Ffff hhhh');
      alert('fffjfndjnfjdnfjd');
      event.returnValue =
        'Are you sure you want to close the popup? Your authentication may not be completed.';
    });
  };

  // // Add an event listener to handle messages from the popup window
  // window.addEventListener('message', (event) => {
  //   if (event.data === 'popupClosed') {
  //     // Handle the closed popup here
  //     handlePopupClosed();
  //   }
  // });

  const handlePopupClosed = () => {
    // Display an error message or take appropriate action in your React application
    console.log('Inner popup window has been closed. Your authentication may not be completed.');
  };

  // const openUtilityWindow = () => {
  //   const authUrl = `https://google.com/`;
  //   // const popup = window.open(authUrl, 'Google Auth', 'width=500,height=600');
  //   openPopupCenter(authUrl, 'Google Auth', 700, 600);
  // };

  const averageAnnualElectricBill = (utilityMonthlyData) => {
    if (utilityMonthlyData.length === 0) {
      return 0;
    }

    // Calculate the average annual electric bill
    const totalElectricBill = utilityMonthlyData.reduce((total, monthData) => {
      if (monthData && monthData.charges) {
        return total + parseFloat(monthData.charges);
      }
      return total;
    }, 0);

    // Assuming utilityMonthlyData contains 12 months of data, you can calculate the annual average
    const averageAnnualBill = totalElectricBill / 12;

    return averageAnnualBill;
  };

  useEffect(() => {
    console.log('urjanet :', urjanet);
    // setTimeout(() => {
    //   triggerModal();
    // }, 2000);
    // if (!urjanet || (urjanet && urjanet.status !== UtilityStatusType.Success)) {
    //   triggerModal();
    // }
    if (!urjanet) {
      triggerModal();
    }
    // triggerModal();
    // window.addEventListener('beforeunload', (event) => {
    //   // Customize the error message here
    //   console.log('Ffff hhhh');
    //   // alert('fffjfndjnfjdnfjd');
    //   event.returnValue =
    //     'Are you sure you want to close the popup? Your authentication may not be completed.';
    // });
    // return () => {
    //   if (popup && !popup.closed) {
    //     popup.close();
    //   }
    // };
    // if (utilityMonthlyData) {
    //   console.log(utilityMonthlyData);
    //   averageAnnualElectricBill(utilityMonthlyData);
    // }
  }, [urjanet, utilityMonthlyData]);

  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={triangleImage} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">
          Your current energy profile
        </p>
        {/* <p className="text-[16px] leading-[24px] text-primary mb-[40px]">
          Maecenas et urna vitae ante feugiat ullamcorper. Ut eget justo suscipit, efficitur purus
          in, rhoncus purus.
        </p> */}
      </div>

      <div className="flex flex-wrap gap-4 justify-between relative w-full">
        <div className="bg-[#F1ECA5] w-full  md:w-[25%] p-[16px] flex-col items-start border-[1px] border-solid border-primary">
          <div>
            <div>
              <p className="text-[16px] text-primary leading-[24px]">
                Average monthly electric bill
              </p>
              <span className="text-[36px] text-primary leading-[44px]">
                {monthlyAverageElectricityCostData &&
                monthlyAverageElectricityCostData.averageCharge
                  ? `$${parseInt(monthlyAverageElectricityCostData.averageCharge).toLocaleString(
                      'en-US'
                    )}`
                  : solarInquiryData && solarInquiryData.utility.avgMonthlyElectricBill
                  ? `$${parseInt(solarInquiryData.utility.avgMonthlyElectricBill).toLocaleString(
                      'en-US'
                    )}`
                  : `$0`}
              </span>
            </div>
            <div className="my-[16px]">
              <p className="text-[16px] text-primary leading-[24px]">
                Average annual electric bill
              </p>
              <span className="text-[36px] text-primary leading-[44px]">
                {/* {solarInquiryData && solarInquiryData.utility.avgAnnualElectricBill
                  ? `$${parseInt(solarInquiryData.utility.avgAnnualElectricBill).toLocaleString(
                      'en-US'
                    )}`
                  : `$0`} */}
                {`$${parseInt(averageAnnualElectricBill(utilityMonthlyData)).toLocaleString(
                  'en-US'
                )}`}
              </span>
            </div>
          </div>
          <div className="border-solid border-primary border-t-[1px] border-b-[1px] py-[16px] mb-[16px]">
            <p className="text-[16px] text-primary leading-[24px]">Estimated annual increase</p>
            <span className="text-[36px] text-primary leading-[44px]">
              {solarInquiryData && solarInquiryData.utility.estimatedAnnualIncrease
                ? `${solarInquiryData.utility.estimatedAnnualIncrease}%`
                : `0%`}
            </span>
          </div>
          <div>
            <p className="text-[16px] text-primary leading-[24px]">30-year cost of doing nothing</p>
            <span className="text-[36px] text-[#BD0000] leading-[44px]">
              {solarInquiryData && solarInquiryData.utility.costOfNothing30Years
                ? `${
                    parseInt(solarInquiryData.utility.costOfNothing30Years) < 0
                      ? `-$${(
                          parseInt(solarInquiryData.utility.costOfNothing30Years) * -1
                        ).toLocaleString('en-US')}`
                      : `$${parseInt(solarInquiryData.utility.costOfNothing30Years).toLocaleString(
                          'en-US'
                        )}`
                  }`
                : `$0`}
            </span>
          </div>
        </div>
        <div className="bg-white p-[16px] md:w-[72%] w-full border-[1px] border-solid border-primary sm:mb-0 mb-[72px]">
          <div>
            <p className="text-[16px] font-aeonik-bold leading-[24px] text-primary">
              Monthly Consumption
            </p>
          </div>
          {utilityMonthlyData && utilityMonthlyData.length ? (
            <div className="flex justify-center">
              <ButtonSelect
                items={[
                  {
                    label: 'kWh',
                    value: 'energy'
                  },
                  {
                    label: 'Cost',
                    value: 'cost'
                  }
                ]}
                selectedValue={mode}
                onChange={(mode) => setMode(mode)}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="border-solid border-b-2 border-primary text-center max-h-[250px] h-[247px] my-[16px] text-primary flex items-center justify-center">
            {/* <MonthlyConsumption mode={mode} {...consumptionData} /> */}
            {utilityMonthlyData && utilityMonthlyData.length ? (
              <MonthlyConsumption mode={mode} {...formatDataForChart(utilityMonthlyData)} />
            ) : (
              <span>No data Found</span>
            )}

            {/* {dummyData && dummyData.length ? (
              <MonthlyConsumption mode={mode} {...formatDataForChart(dummyData)} />
            ) : (
              <span>No data Found</span>
            )} */}
          </div>
          {/* <div className="text-right">
            <p className="text-[16px] text-primary leading-[24px]">Total Due</p>
            <span className="text-[36px] text-primary leading-[44px]">
              {solarInquiryData && solarInquiryData.utility.totalDue
                ? `${
                    parseInt(solarInquiryData.utility.totalDue) < 0
                      ? `-$${(parseInt(solarInquiryData.utility.totalDue) * -1).toLocaleString(
                          'en-US'
                        )}`
                      : `$${parseInt(solarInquiryData.utility.totalDue).toLocaleString('en-US')}`
                  }`
                : `$0`}
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Step3;
