import React from 'react';

const ArrowRightIcon = ({ stroke = 'white' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5 12H0.5" stroke={stroke} strokeWidth="1.91" strokeMiterlimit="10" />
      <path
        d="M13.8601 4.36L21.5001 12L13.8601 19.64"
        stroke={stroke}
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default ArrowRightIcon;
