import React from 'react';
import ProgressiveChart from './ProgressiveChart/ProgressiveChart';

const TotalSaving = (props) => {
  return (
    <>
      <ProgressiveChart
        type="line"
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time'
            },
            y: {
              type: 'linear',
              title: {
                display: true,
                text: 'Annual Energy Spend'
              },
              ticks: {
                format: {
                  style: 'currency',
                  currency: 'USD'
                }
              }
            }
          },
          plugins: {
            filler: {
              propagate: true
            },
            legend: {
              display: true
            },
            tooltip: {
              callbacks: {
                label: () => '',
                title(items) {
                  return items.map(
                    (i) => `${new Date(i.parsed.x).getFullYear()}: ${i.formattedValue}`
                  );
                }
              }
            }
          }
        }}
        data={{
          labels: props.labels,
          datasets: props.datasets
        }}
      />
    </>
  );
};

export default TotalSaving;
