import React, { Fragment, useMemo, useState } from 'react';
import './TimelineGraph.scss';
import cardImg1 from '../../assets/images/card-bg-img1.png';
import cardImg2 from '../../assets/images/card-bg-img2.png';
import cardImg3 from '../../assets/images/card-bg-img3.png';
import cardImg4 from '../../assets/images/card-bg-img4.png';
import cardImg5 from '../../assets/images/card-bg-img5.png';
import cardImg6 from '../../assets/images/card-bg-img6.png';
import TimelineCard from './Components/Cards/TimelineCard';
import TimelineModal from './Components/Modal/TimelineModal';
import Button from '../Button/Button';
import { MdArrowForward } from 'react-icons/md';

const TimelineGraph = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [step, setStep] = useState(0);

  const data = useMemo(
    () => [
      {
        title: 'Review Agreement',
        time: '1 day',
        children: (
          <>
            <p>
              You will sign a few agreements to initiate your solar system. You’ll sign an
              installation agreement, a financing agreement with the financing company underwriting
              the loan, and potentially a utility interconnection agreement if required.
            </p>
            <p className="py-4">
              We’ll generate all these documents for you in your customer portal to make the
              documentation process seamless. You will digitally sign these documents, and signed
              versions will be emailed to you.
            </p>
            <Button
              onClick={() => undefined}
              size="large"
              className="!font-aeonik-bold"
              theme="secondary">
              <p className="!min-w-[132px]">Review & sign docs</p>
            </Button>
          </>
        )
      },
      {
        title: 'Site survey',
        time: '1 hour',
        children: (
          <>
            <p>
              We’ll have you take a few pictures to prepare our installers for the job. You’ll take
              photos of your roof (From the ground!) and a picture of your utility meter and service
              panel.
            </p>
            <p className="pt-4">You can submit these photos conveniently in the Daylight app.</p>
          </>
        )
      },
      {
        title: 'Engineering & Design Approval',
        time: '3 days to 2 weeks',
        children: (
          <p>
            Our designers will convert the design into a CAD drawing for final panel placements and
            electric requirements. We’ll send this over to you to review.
          </p>
        )
      },
      {
        title: 'Permitting & Utility Interconnection',
        time: '2 to 7 weeks',
        children: (
          <p>
            We will submit all necessary paperwork to the utility and local jurisdiction for
            approvals. You won’t need to do anything. We’ll keep you updated on the process.
            Township and utility approvals typically take a few weeks.
          </p>
        )
      },
      {
        title: 'Installation',
        time: '6 hours to 3 days',
        children: (
          <p>
            We will schedule the installation with you. Our installers will come on-site to install
            your new hardware and connect it to your home electrical service. Final inspections will
            be coordinated with the township and utility.
          </p>
        )
      },
      {
        title: 'Power on!',
        children: (
          <p>
            Your utility will give your system permission to operate, and your system will turn on.
            Now you’ll start harvesting energy from the sun and saving on all your bills!
          </p>
        )
      }
    ],
    []
  );

  const showStepModal = (n) => {
    setStep(n);
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="relative">
      <div className="flex justify-center items-center timeline-graph-container ">
        <div className="timeline-graph-start">Start</div>
        <ul className="flex timeline-graph-ul">
          <li className="timeline-graph-li top even ">
            <span onClick={() => showStepModal(0)}>
              <TimelineCard image={cardImg1} title={'Review agreement'} />
            </span>
          </li>
          <li className="timeline-graph-li bottom odd">
            <span onClick={() => showStepModal(1)}>
              <TimelineCard image={cardImg2} title={'Site Survey'} />
            </span>
          </li>
          <li className="timeline-graph-li top even">
            <span onClick={() => showStepModal(2)}>
              <TimelineCard image={cardImg3} title={'Engineering & Design Approval'} />
            </span>
          </li>
          <li className="timeline-graph-li bottom odd">
            <span onClick={() => showStepModal(3)}>
              <TimelineCard image={cardImg4} title={'Permitting'} />
            </span>
          </li>
          <li className="timeline-graph-li top even">
            <span onClick={() => showStepModal(4)}>
              <TimelineCard image={cardImg5} title={'Installation'} />
            </span>
          </li>
          <li className="timeline-graph-li bottom odd">
            <span onClick={() => showStepModal(5)}>
              <TimelineCard image={cardImg6} title={'Power on!'} />
            </span>
          </li>
        </ul>
        <div className="timeline-graph-finish sm:mb-0 mb-[72px]">
          <span>Finish</span>
        </div>
      </div>
      <TimelineModal
        {...data[step]}
        step={step + 1}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        hasNext={step + 1 < data.length}
        onNext={() => (step + 1 < data.length ? setStep(step + 1) : toggleModal())}
      />
    </div>
  );
};

export default TimelineGraph;
