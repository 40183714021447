import React from 'react';

const MoneyDonationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_2817_30617)">
        <path
          d="M15.82 14.86H8.18H1.5V1.5H22.5V14.86H19.64"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M9.13989 11.0499H12.4799C12.8601 11.0473 13.2238 10.8944 13.4917 10.6247C13.7596 10.3549 13.9099 9.99013 13.9099 9.60995C13.9099 9.23069 13.7592 8.86696 13.4911 8.59878C13.2229 8.33061 12.8592 8.17995 12.4799 8.17995H11.4799C11.1006 8.17995 10.7369 8.02929 10.4687 7.76111C10.2006 7.49293 10.0499 7.12921 10.0499 6.74995C10.0499 6.37069 10.2006 6.00696 10.4687 5.73878C10.7369 5.47061 11.1006 5.31995 11.4799 5.31995H14.8199"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path d="M12 3.41003V5.32003" stroke="#15049E" strokeWidth="1.91" strokeMiterlimit="10" />
        <path d="M12 11.05V12.95" stroke="#15049E" strokeWidth="1.91" strokeMiterlimit="10" />
        <path
          d="M17.7299 22.5C18.9527 21.2774 19.6397 19.6192 19.6399 17.89V13.89C19.6346 13.3869 19.4311 12.9062 19.0735 12.5524C18.7158 12.1985 18.233 12 17.7299 12C17.4787 11.9987 17.2298 12.0472 16.9974 12.1427C16.7651 12.2382 16.554 12.3789 16.3764 12.5565C16.1988 12.7341 16.0581 12.9452 15.9626 13.1775C15.8671 13.4099 15.8186 13.6588 15.8199 13.91V16.77L15.6199 16.87C15.1061 17.1278 14.674 17.5233 14.3721 18.0125C14.0701 18.5017 13.9101 19.0652 13.9099 19.64"
          stroke="#15049E"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
        <path
          d="M8.17993 14.86V17.86C8.18011 19.5891 8.86715 21.2474 10.0899 22.47"
          stroke="black"
          strokeWidth="1.91"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2817_30617">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoneyDonationIcon;
