import React from 'react';

const LeafShieldIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.9999 22.5C6.99991 21.39 3.40991 16.5 3.40991 10.86V5.32L11.9999 1.5L20.5899 5.32V10.86C20.5899 16.52 16.9999 21.39 11.9999 22.5Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9951 6.40234L13.7346 8.14183C14.5224 8.93329 14.9652 10.0041 14.9665 11.1208C14.9678 12.2375 14.5275 13.3094 13.7417 14.1027L12.0022 15.8422L10.2698 14.1098C9.4765 13.3165 9.03084 12.2406 9.03084 11.1187C9.03084 9.99688 9.4765 8.92096 10.2698 8.12768L11.9951 6.40234Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
      <path d="M12 18.55V10.09" stroke="#15049E" strokeWidth="1.91" strokeMiterlimit="10" />
    </svg>
  );
};

export default LeafShieldIcon;
