import React, { useContext, useState } from 'react';
import bgShape10 from '../../../assets/images/bg_shape_10.png';
import Button from '../../Button/Button';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CloseCircularIcon from '../../../resources/icons/CloseCircularIcon';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { SolarDataContext } from '../../../provider/solarData.provider';

const Step10 = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const { solarInquiryData } = useContext(SolarDataContext);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  return (
    <div className="pt-[40px] pb-[85px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={bgShape10} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">Solar system quote</p>
      </div>

      <div className="flex flex-col justify-between relative w-full">
        <div className="w-full flex flex-col md:flex-row justify-center gap-6">
          <div className="lg:w-1/2 w-full">
            <div className="flex flex-col md:flex-row  gap-4">
              <div className="bg-primary text-white px-[16px] py-[24px] xl:min-w-[214px] ">
                <p className="text-[14px] leading-[20px] text-center mb-[8px]">
                  Your new monthly energy payment will be
                </p>
                <p className="xlg:text-[57px] text-[44px] leading-[64px] text-center">
                  $
                  {solarInquiryData && solarInquiryData.newMonthEnergyPayment
                    ? parseInt(solarInquiryData.newMonthEnergyPayment).toLocaleString('en-US')
                    : 0}
                  <span className="text-[16px] leading-[24px]">/mo</span>
                </p>
              </div>
              <div className="bg-primary text-white px-[16px] py-[24px] xl:min-w-[214px] ">
                <p className="text-[14px] leading-[20px] text-center mb-[8px]">
                  Estimated monthly SREC income
                </p>
                <p className="xlg:text-[57px] text-[44px]  leading-[64px] text-center">
                  $
                  {solarInquiryData && solarInquiryData.monthlyDaylighCashback
                    ? parseInt(solarInquiryData.monthlyDaylighCashback).toLocaleString('en-US')
                    : 0}
                  <span className="text-[16px] leading-[24px]">/mo</span>
                </p>
              </div>
              <div className="bg-secondary text-[#00330E] px-[16px] py-[24px] xl:min-w-[214px]">
                <p className="text-[14px] leading-[20px] text-center mb-[8px]">
                  Your new monthly total (payment - SREC income)
                </p>
                <p className="xlg:text-[57px] text-[44px] leading-[64px] text-center">
                  $
                  {solarInquiryData && solarInquiryData.newMonthPaymentCashback
                    ? parseInt(solarInquiryData.newMonthPaymentCashback).toLocaleString('en-US')
                    : 0}
                  <span className="text-[16px] leading-[24px]">/mo</span>
                </p>
              </div>
            </div>
            <div className="p-[16px] text-primary border-[1px] border-solid border-primary mt-[10px]">
              <div className="text-[16px] leading-[24px]">
                <p className="mb-[26px]">
                  Your monthly payment may vary by the loan product you choose, your credit score,
                  the term of the loan, and whether you use your tax credit to pay down 30% of the
                  loan. Your Daylight Concierge will help you find the best financing plan to match
                  your objectives.
                </p>

                <p className="mb-[36px]">
                  Additionally, [New Jersey] offers SRECs to offset a large portion of your monthly
                  cost. SRECs are worth [$91] for every MWh your system produces. This SREC income
                  will reduce most of the monthly expense associated with financing your solar
                  system, leaving your total monthly cost at just [$
                  {solarInquiryData && solarInquiryData.newMonthPaymentCashback
                    ? parseInt(solarInquiryData.newMonthPaymentCashback).toLocaleString('en-US')
                    : 0}
                  ]!
                </p>
                {/* <p className="mb-[16px]">
                  Click below to prequalify. This soft credit check will not impact your credit
                  score.
                </p> */}
              </div>
              {/* <Button
                onClick={() => undefined}
                size="large"
                className="!text-[16px]"
                theme="secondary">
                Get prequalified
              </Button> */}
            </div>
          </div>

          <div className="border-[1px] border-solid border-primary p-[16px] md:w-1/2 w-full bg-white">
            <ul>
              <li className="flex justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
                <div className="text-[16px] font-aeonik-bold leading-[24px]">
                  Cash price summary
                </div>
                <div className="text-[16px] leading-[24px]">
                  {solarInquiryData && solarInquiryData.cashPriceSummery
                    ? solarInquiryData.cashPriceSummery
                    : 0}
                  kW System
                </div>
              </li>
              <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
                <div className="flex justify-between items-center">
                  <p>Year 1 System Production</p>{' '}
                  <span>
                    {solarInquiryData && solarInquiryData.year1SystemProduction
                      ? parseInt(solarInquiryData.year1SystemProduction).toLocaleString('en-US')
                      : 0}
                    kWh
                  </span>
                </div>
                <div className="flex justify-between items-center py-[8px]">
                  <p>Solar Offset</p>{' '}
                  <span>
                    {solarInquiryData && solarInquiryData.solarOffSetAmount
                      ? parseFloat(
                          parseFloat(solarInquiryData.solarOffSetAmount).toFixed(1)
                        ).toLocaleString('en-US')
                      : 0}
                    %
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <p>Number of Panels</p>{' '}
                  <span>
                    {solarInquiryData && solarInquiryData.numberOfPanels
                      ? parseInt(solarInquiryData.numberOfPanels).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
              </li>
              <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
                <div className="flex justify-between items-center">
                  <p>Gross System Cost</p>{' '}
                  <span>
                    $
                    {solarInquiryData && solarInquiryData.grossSystemCost
                      ? parseInt(solarInquiryData.grossSystemCost).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
                <div className="flex justify-between items-center py-[8px]">
                  <p>Federal, Local, & State Incentives</p>{' '}
                  <span className="text-[#00330E]">
                    $
                    {solarInquiryData && solarInquiryData.incentives
                      ? parseInt(solarInquiryData.incentives).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
                {/* <div className="flex justify-between items-center">
                  <p>Daylight Cashback</p>{' '}
                  <span className="text-[#00330E]">
                    $
                    {solarInquiryData && solarInquiryData.daylightCashback
                      ? parseInt(solarInquiryData.daylightCashback).toLocaleString('en-US')
                      : 0}
                  </span>
                </div> */}
              </li>
              <li className="py-[16px] text-[16px] leading-[24px] text-primary">
                <div className="flex justify-between">
                  <p>Net System Cash Cost</p>{' '}
                  <span className="lg:text-[57px] md:text-[18px] text-[44px] leading-[64px]">
                    $
                    {solarInquiryData && solarInquiryData.netSystemCashCost
                      ? parseInt(solarInquiryData.netSystemCashCost).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="px-[16px] w-full text-primary ml-[8px] mt-[10px] sm:mb-0 mb-[72px]">
          <span>
            <u
              id="step-10-see-more"
              className="text-[11px] font-aeonik-bold leading-[16px] tracking-[0.1px]"
              onClick={toggleTooltip}>
              See more
            </u>
            <ReactTooltip
              id="custom-tooltip-for-step-10"
              anchorSelect="#step-10-see-more"
              className="!bg-primary !p-[15px] !shadow-lg"
              effect="solid"
              place="top-end"
              clickable={true}
              delayHide={100}
              isOpen={isTooltipVisible}
              openOnClick={true}
              opacity={1}>
              <div className="text-[12px] text-[#fff] md:max-w-[674px] max-w-[280px] m-auto">
                <div className="flex justify-between items-center mb-[5px]">
                  <span className="uppercase text-[12px] font-aeonik-bold leading-[16px]">
                    disclaimer
                  </span>
                  <span
                    className="[&>svg]:w-[20px] [&>svg]:h-[20px] cursor-pointer"
                    onClick={toggleTooltip}>
                    {/* <CloseCircularIcon stroke={'#fff'} /> */}
                    <AiOutlineCloseCircle size={24} className="!fill-white" />
                  </span>
                </div>
                <div className="text-[12px] leading-[16px] tracking=[0.1px] pr-[22px]">
                  Any monthly payments shown are estimations only and do not guarantee financing.
                  Your actual payment could vary based on your credit profile and being approved by
                  the financial institution. Lender rates, fees or programs are subject to change
                  without notice. <br />
                  <br /> As the purchaser and owner of a solar photovoltaic system, you may qualify
                  for certain federal, state, local or other rebates, tax credits or incentives
                  (collectively, “Incentives”). If you have any questions as to whether and when you
                  qualify for any Incentives and the amount of such Incentives, please consult and
                  discuss with your personal tax or financial advisor. Daylight makes no
                  representation, warranty or guaranty as to the availability or amount of such
                  Incentives.
                </div>
              </div>
            </ReactTooltip>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Step10;
