import React from 'react';
import styles from './Button.module.scss';
import { Link } from 'react-router-dom';

// interface Props {
//   className?: string;
//   type?: 'button' | 'link' | 'external link';
//   theme?: 'primary' | 'secondary' | 'tertiary' | 'transparent';
//   size?: 'large' | 'medium' | 'small' | 'x-small';
//   width?: 'full' | 'auto';
//   buttonType?: 'button' | 'reset' | 'submit';
//   icon?: JSX.Element;
//   iconPosition?: 'left' | 'right';
//   disabled?: boolean;
//   destructive?: boolean;
//   linkOptions?: {
//     to: any
//   };
//   externalLinkOptions?: {
//     href: string
//   };
//   onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
//   onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
// }

const Button = ({
  className = '',
  type = 'button',
  theme = 'secondary',
  size = 'medium',
  width = 'auto',
  buttonType = 'button',
  icon,
  iconPosition = 'left',
  disabled = false,
  destructive = false,
  linkOptions,
  externalLinkOptions,
  onClick,
  onBlur,
  children
}) => {
  return (
    <>
      {type === 'button' && (
        <button
          className={`${styles.button} ${styles[theme]} ${styles[size]} ${styles[width]} ${
            destructive && styles.destructive
          } ${styles[iconPosition]} ${!children && styles.iconOnly} ${className && className}`}
          type={buttonType}
          onClick={onClick}
          onBlur={onBlur}
          disabled={disabled}>
          {icon && <span className={styles.button__Icon}>{icon}</span>}
          {children && <span className={styles.button__Text}>{children}</span>}
        </button>
      )}

      {type === 'link' && (
        <Link
          className={`${styles.button} ${styles[theme]} ${styles[size]} ${styles[width]} ${
            destructive && styles.destructive
          } ${styles[iconPosition]} ${!children && styles.iconOnly} ${className && className}`}
          to={linkOptions?.to}>
          {icon && <span className={styles.button__Icon}>{icon}</span>}
          {children && <span className={styles.button__Text}>{children}</span>}
        </Link>
      )}

      {type === 'external link' && (
        <a
          className={`${styles.button} ${styles[theme]} ${styles[size]} ${styles[width]} ${
            destructive && styles.destructive
          } ${styles[iconPosition]} ${!children && styles.iconOnly} ${className && className}`}
          href={externalLinkOptions && externalLinkOptions.href}
          target={'_blank'}
          rel="noreferrer">
          {icon && <span className={styles.button__Icon}>{icon}</span>}
          {children && <span className={styles.button__Text}>{children}</span>}
        </a>
      )}
    </>
  );
};

export default Button;
