import React, { useContext, useEffect } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { StepperContext } from '../../../../provider/stepper.provider';
import Button from '../../../Button/Button';
import { LeadContext } from '../../../../provider/lead.provider';
import { sidebarItems } from '../StepperHeader/components/SideBar/SidebarItems';
import { SolarDataContext } from '../../../../provider/solarData.provider';

const StepperFooter = () => {
  const { stepsArray, activeStep, setActiveStep } = useContext(StepperContext);
  const { authToken } = useContext(SolarDataContext);
  const {
    currentLeadStep,
    setCurrentLeadStep,
    leadId,
    addLeadStep,
    updateUserLead,
    generateLeadId,
    visitedSteps,
    addVisitedStep
  } = useContext(LeadContext);
  const totalSteps = stepsArray.length;

  const handleNext = async () => {
    const nextStepIndex = activeStep + 1;
    if (nextStepIndex < totalSteps) {
      setActiveStep(nextStepIndex);
    }
    if (authToken && leadId) {
      await trackStepsLeads('Next');
    }
  };

  const trackStepsLeads = async (type) => {
    // console.log('visitedSteps :', visitedSteps);
    if (
      !visitedSteps.some(
        (visitedStep) => visitedStep.step === (type === 'Next' ? activeStep + 1 : activeStep - 1)
      )
    ) {
      // console.log('Hello :', activeStep);
      sidebarItems.map(async (item) => {
        if (item.step === (type === 'Next' ? activeStep + 1 : activeStep - 1)) {
          await addLeadStep({ type: 'Next Button' }, item.title);
          addVisitedStep(item);
        }
      });
    }
  };

  const handlePrevious = async () => {
    const prevStepIndex = activeStep - 1;
    if (prevStepIndex >= 0) {
      setActiveStep(prevStepIndex);
    }

    if (authToken && leadId) {
      await trackStepsLeads('Back');
    }
  };

  useEffect(() => {
    const callGenerateLead = async () => {
      try {
        const generateLead = await generateLeadId();

        if (generateLead && generateLead.data.createLead.lead.id) {
          try {
            const LeadRes = await addLeadStep(
              { type: 'User landed to mini website' },
              'miniwebsite-initial-step',
              generateLead.data.createLead.lead.id
            );
            if (LeadRes) {
              addVisitedStep({ title: 'Get Started Step', step: 0 });
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (visitedSteps.length === 0 && authToken && !leadId) {
      callGenerateLead();
    }
  }, [authToken]);

  return (
    <div className="flex sm:justify-between justify-center items-center">
      {activeStep !== 0 && (
        <div className="flex">
          {/* <MdArrowBack
            size={24}
            className="fill-primary flex items-center text-center"
            onClick={handlePrevious}
          />
          <span className="text-primary text-[16px] ml-[16px]"> Back </span> */}

          <Button
            onClick={handlePrevious}
            size="large"
            icon={
              <MdArrowBack size={24} className="!fill-primary absolute left-[24px] top-[14px]" />
            }
            className="!py-[14px] hover:!bg-gray-200 !text-[16px] !leading-[24px]"
            iconPosition="left"
            theme="transparent">
            <p className="!min-w-[75px] text-primary">Back </p>
          </Button>
        </div>
      )}
      <div>
        <Button
          onClick={handleNext}
          size="large"
          icon={
            <MdArrowForward
              size={24}
              className="!fill-[#ffffff] absolute right-[24px] top-[16px]"
            />
          }
          className="!py-[18px]"
          iconPosition="right"
          theme="primary">
          <p className="!min-w-[132px]">{activeStep === 0 ? `Get Started` : `Next`} </p>
        </Button>
      </div>
    </div>
  );
};

export default StepperFooter;
