import { gql } from '@apollo/client';

export const LOAD_USERS = gql`
  query {
    getAllUsers {
      id
      firstName
      email
      password
    }
  }
`;

export const URJANET_UTILITY_LIST = gql`
  query UrjanetListProvider(
    $providerName: String
    $providerNameContains: String
    $offset: Int!
    $first: Int!
  ) {
    urjanetUtilityProvider(
      providerName: $providerName
      providerNameContains: $providerNameContains
      offset: $offset
      first: $first
    ) {
      edges {
        node {
          id
          providerName
          providerId
        }
      }
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      accounts {
        accountType
        credential {
          id
          status
        }
      }
    }
  }
`;

export const INTEGRATIONS = gql`
  query Integrations {
    integrations {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const SOLAR_CONFIGURATION = gql`
  query SolarConfiguration {
    solarConfiguration {
      batteryCostPerBattery
      batteryItcRate
      batteryPaymentPerBattery
      daylightCashbackPpw
      daylightCashbackRate
      federalItcRate
      financeRate
      inflationRate
      solarDegradationFactor
      systemPriceRate
      termInYears
    }
  }
`;

export const UTILITY = gql`
  query Utility($state: String!, $zipcode: String) {
    utility(where: { state: $state, zipCode: $zipcode }) {
      zipCode
      name
      state
      utilityType
      avgResidentialRate
    }
  }
`;

export const SOLAR_INQUIRY_QUERY = gql`
  query SolarInquiryQuery {
    solarInquiry {
      id
      address
      batteryQuantity
      city
      earnCashbackUpTo
      electricityCost
      email
      energyBillSaving
      estSolarSystemSizeKw
      federalItcAmount
      monthlyEnergyCost
      netSolarSystemPrice
      phoneNumber
      progress
      savingOver25Years
      sizeSmoothing
      state
      systemPrice
      treesPlanted
      wattCost
      zipCode
    }
  }
`;

export const SOLAR_PROPOSAL_QUERY = gql`
  query SolarProposalQuery($email: String) {
    solarProposal(email: $email) {
      customerAddress {
        address
        avgMonthlyElectricityCost
        city
        electricityCost
        email
        familyType
        id
        latitude
        longitude
        openSolarErrorData
        phoneNumber
        progress
        state
        zipCode
      }
      solarProposalPdf {
        pdf
        systemName
        id
      }
      customDesignFor
      customDesignForCreatedBy
      avgMonthlyElectricityBill
      avgAnnualElectricityBill
      estimatedAnnualIncrease
      thirtyYearCostOfDoingNothing
      totalDue
      mothlyConsumption
      panelType
      inverter
      futureAddOns
      solarOffset
      totalSavingOver30Years
      imgOfHomeWithIrradanceAndSolarPannels
      savingGraphOver30Years
      perMonthPayment
      perMonthCashback
      newMonthTotalPaymentCashback
      year1SystemProduction
      solarOffset
      sizeSmoothing
      systemSize
      federalLocalStateIncentives
      grossSystemCost
      daylightCashback
      netSystemCashCost
      cashPriceSummary
      grossInstalledCost
      federalIncentives
      localStateIncentives
      netBatteryCost
      batteryQuantityMin
      numberOfPanels
      newMonthEnergyPayment
      batteryBrand
      batteryDaylightCashback
      newMonthPaymentCashback
      id
    }
  }
`;

export const UTILITY_HISTORY_RATE_QUERY = gql`
  query utilityRateHistoryQuery($stateCode: String!) {
    utilityRateHistory(stateCode: $stateCode) {
      id
      rate
      rateType
      stateCode
      stateName
      date
    }
  }
`;

export const OPEN_SOLAR_PROJECT_DATA_QUERY = gql`
  query openSolarProjectDataQuery {
    openSolarProject {
      usage
      url
      usageAnnualOrGuess
      yearsToSimulate
      zip
      title
      timezoneOffset
      testimonials
      systems {
        adders
        addersPerPanel
        addersPerSystem
        addersPerWatt
        additionalCosts
        allowShadeMitigation
        annotations
        autoString
        basicMode
        basicPriceOverride
        basicPriceOverridePerWatt
        batteries {
          code
          batteryId
          agingFactor
          depthOfDischargeFactor
          efficiencyFactor
          endOfLifeCapacity
          id
          kwhOptimal
          manufacturerName
          migrationId
          orgId
          powerMaxContinuous
          voltage
          warrantyKwh1CyclePerDay
        }
        batteryControlScheme
        batteryControlSchemeData
        batteryTotalKwh
        bills {
          currentBill
          hashedArgs
          lowerBoundScalingFactors
          proposedBill
          scalingFactorsLabel
          upperBoundScalingFactors
        }
        calculator
        commissionId
        consumption
        contractTemplateOverrides
        costingOverride
        discount
        electrical
        environmentals
        image
        incentives {
          automatic
        }
        inverterRange
        inverters {
          code
          efficiency
          electrical
          id
          manufacturerName
          maxPowerRating
        }
        name
        systemBatteries
        systemIncentives
        systemInverters
        systemLifetime
        systemModules
        systemPanelPlacement
      }
      state
      stars
      stage
      soldDate
      siteNotes
      synStatus
      systemImage
      roofType
      powerFactor
      isResidential
      lat
      lon
      leadSource
      locality
      countryName
      county
      customerProposalData
      businessName
      contacts {
        firstName
        familyName
        phone
        createdBy
      }
      address
      assignedRoleData
      assignedRoleName
    }
  }
`;

export const MONTHLY_AVERAGE_ELECTRICITY_COST_QUERY = gql`
  query MonthlyAverageElectricityCostQuery {
    monthlyAverageElectricityCost {
      averageCharge
      averageUsage
    }
  }
`;

export const UTILITY_MONTHLY_DATA_QUERY = gql`
  query UtilityMonthlyDataQuery {
    utilityMonthlyData {
      charges
      usage
      endDate
    }
  }
`;

export const DECODE_NOUNCE_QUERY = gql`
  query DecodeNounce($nonce: UUID!) {
    decodeNounce(nonce: $nonce) {
      nounce
      restToken
      token
    }
  }
`;

export const UTILITY_RATE_HISTORICAL_ANNUAL_QUERY = gql`
  query UtilityRateHistoricalAnnual($utilityName: String, $state: String) {
    utilityRateHistoricalAnnual(where: { utilityName: $utilityName, state: $state }) {
      serviceType
      state
      utilityNumber
      utilityName
      year1997
      year1998
      year1999
      year2000
      year2001
      year2002
      year2003
      year2004
      year2005
      year2006
      year2007
      year2008
      year2009
      year2010
      year2011
      year2012
      year2013
      year2014
      year2015
      year2016
      year2017
      year2018
      year2019
      year2020
      year2021
      year2022
    }
  }
`;
