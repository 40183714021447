import ProgressiveChart from './ProgressiveChart/ProgressiveChart.jsx';

function EnergyBill(props) {
  return (
    <>
      <ProgressiveChart
        type="line"
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time'
            },
            y: {
              type: 'linear',
              title: {
                display: true,
                text: '$/kWh'
              },
              ticks: {
                format: {
                  style: 'currency',
                  currency: 'USD'
                }
              }
            }
          },
          animation: {
            easing: 'linear',
            duration: 2000
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: () => '',
                title(items) {
                  return items.map((i) => `${i.raw.x}: ${i.formattedValue}`);
                }
              }
            }
          }
        }}
        data={{
          labels: props.labels,
          datasets: props.datasets
        }}
      />
    </>
  );
}

export default EnergyBill;
