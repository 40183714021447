import React from 'react';

const StorageBoxIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.5 1.5H1.5V6.27H22.5V1.5Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
      <path
        d="M21.5402 6.26953H2.4502V22.4995H21.5402V6.26953Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9099 10.09H10.0899C9.03507 10.09 8.17993 10.9451 8.17993 12C8.17993 13.0548 9.03507 13.91 10.0899 13.91H13.9099C14.9648 13.91 15.8199 13.0548 15.8199 12C15.8199 10.9451 14.9648 10.09 13.9099 10.09Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default StorageBoxIcon;
