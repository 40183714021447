import React, { useContext, useEffect, useState } from 'react';
import bgShape10 from '../../../assets/images/bg_shape_11.png';
import Button from '../../Button/Button';
import CloseCircularIcon from '../../../resources/icons/CloseCircularIcon';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ModalContext } from '../../../provider/modal.provider';
import BatteryIcon from '../../../resources/icons/BatteryIcon';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { SolarDataContext } from '../../../provider/solarData.provider';
import { useMutation } from '@apollo/client';
import { UPDATE_SOLAR_PROPOSAL_MUTATION } from '../../../GraphQL/Mutations';

const Step11 = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isBatteryAdded, setIsBatteryAdded] = useState(false);
  const { openModal, setModalParams } = useContext(ModalContext);
  const { solarInquiryData, updateSolarInquiryData } = useContext(SolarDataContext);

  const [updateSolarProposalMutation, { loading: updateSolarProposalMutationLoading }] =
    useMutation(UPDATE_SOLAR_PROPOSAL_MUTATION);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const addBattery = async () => {
    const updatedRes = await updateProposal({
      ...solarInquiryData,
      batteryQuantity: solarInquiryData.batteryQuantity + 1
    });
    if (updatedRes && updatedRes.data.updateSolarProposal.success) {
      updateSolarInquiryData({ batteryQuantity: solarInquiryData.batteryQuantity + 1 });
      setIsBatteryAdded(true);
    }
  };

  const removeBattery = async () => {
    console.log('remove battery');

    const updatedRes = await updateProposal({
      ...solarInquiryData,
      batteryQuantity: solarInquiryData.batteryQuantity - 1
    });
    if (updatedRes && updatedRes.data.updateSolarProposal.success) {
      updateSolarInquiryData({ batteryQuantity: solarInquiryData.batteryQuantity - 1 });
      setIsBatteryAdded(false);
    }
  };

  const updateProposal = async (solarInquiryData) => {
    console.log('solarInquiryData :', solarInquiryData);
    try {
      const solarInquiryResponse = await updateSolarProposalMutation({
        variables: {
          solarProposalInput: {
            id: solarInquiryData.id,
            batteryQuantityMin: solarInquiryData.batteryQuantity
          }
        }
      });
      console.log('solarInquiryResponse :', solarInquiryResponse);
      return solarInquiryResponse;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (solarInquiryData && solarInquiryData.batteryQuantity > 0) {
      setIsBatteryAdded(true);
    } else {
      setIsBatteryAdded(false);
    }
  }, [solarInquiryData]);

  const triggerModal = () => {
    openModal();

    const activeButtonOnPopUp = isBatteryAdded
      ? [
          {
            text: 'Remove battery',
            action: () => {
              removeBattery();
            }
          }
        ]
      : [
          {
            text: 'Add battery',
            action: () => {
              addBattery();
            }
          }
        ];

    setModalParams({
      contentClassName: '!border-none !p-0',
      content: (
        <div>
          <span className="flex justify-center items-center">
            <BatteryIcon />
          </span>
          <h4 className="px-[40px] py-[20px] font-aeonik-bold text-[24px] leading-[24px] flex justify-center items-center">
            {isBatteryAdded ? `Battery Added` : `Add Battery`}
          </h4>

          <p className="text-[16px] leading-[24px] text-primary py-[15px] px-[80px] flex justify-center items-center">
            {isBatteryAdded
              ? `We have noted your interest in attaching a battery to your solar system in your documentation.`
              : `Please confirm adding a battery to your energy system for backup power.`}
          </p>
        </div>
      ),
      buttons: activeButtonOnPopUp
    });
  };

  return (
    <div className="pt-[30px] pb-[90px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={bgShape10} className="xl:w-[35%] lg:w-[45%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[20px]">
          Add complete energy independence
        </p>
      </div>

      <div className="flex flex-col gap-4 justify-between relative w-full">
        <div className="w-full flex flex-col lg:flex-row justify-center gap-6">
          <div className="lg:w-[60%] w-full">
            <div className="text-primary">
              <p className="text-[16px] leading-[24px]">
                While not required, a home battery can supercharge your solar panels and set you up
                for the grid of the future. For less than the price of a cup of coffee per day☕,
                you can secure uninterrupted power with your own microgrid.
              </p>
            </div>
            <div className=" text-primary">
              <h2 className="text-primary font-aeonik-bold text-[22px] mb-[16px] mt-[32px]">
                Battery Benefits:
              </h2>
              <div className="flex flex-col md:flex-row leading-[24px] gap-[23px]">
                <div className="px-[13px] py-[17px] md:max-w-[207px] w-full bg-secondary">
                  <h5 className="text-[14px] font-aeonik-bold leading-[20px] text-primary mb-[11px]">
                    Backup During Blackouts
                  </h5>
                  <p className="text-[12px] leading-[16px] tracking-[0.1px] text-primary">
                    Enjoy peace of mind knowing that you&apos;ll have a reliable energy source
                    during power outages. <br />
                    <br /> Your home battery and solar panels work together as your own personal
                    power plant, giving you seamless, uninterrupted electricity supply.
                  </p>
                </div>

                <div className="px-[13px] py-[17px] md:max-w-[207px] w-full bg-secondary">
                  <h5 className="text-[14px] font-aeonik-bold leading-[20px] text-primary mb-[11px]">
                    Lower Energy Bills
                  </h5>
                  <p className="text-[12px] leading-[16px] tracking-[0.1px] text-primary">
                    By storing surplus energy instead of sending it back to the grid, you can
                    further reduce your electricity costs. Your home battery allows you to utilize
                    every bit of sunshine your panels capture, maximizing your investment in solar.
                  </p>
                </div>
                <div className="px-[13px] py-[17px] md:max-w-[207px] w-full bg-secondary">
                  <h5 className="text-[14px] font-aeonik-bold leading-[20px] text-primary mb-[11px]">
                    Earn More Rewards
                  </h5>
                  <p className="text-[12px] leading-[16px] tracking-[0.1px] text-primary">
                    A battery helps you maximize participation in Daylight’s Community Energy
                    Network to earn even more cash and rewards - up to $750/year in certain areas!
                    Our Community Energy Network works to stabilize the energy grid and shares the
                    benefits with everyone.
                  </p>
                </div>
              </div>

              <div className="mt-[16px] pt-[16px] px-[24px]">
                {!isBatteryAdded ? (
                  <Button
                    onClick={() => triggerModal()}
                    size="large"
                    className="!text-[16px]"
                    theme="secondary">
                    Add a battery
                  </Button>
                ) : (
                  <Button
                    onClick={() => triggerModal()}
                    size="large"
                    className="!text-[16px] !py-[18px]"
                    theme="primary">
                    Battery added
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="border-[1px] border-solid border-primary p-[16px] lg:w-[40%] w-full bg-white lg:mx-[100px] mx-0 xl:h-[386px] h-[425px] mt-[55px]">
            <ul>
              <li className="flex justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
                <div className="text-[16px] font-aeonik-bold leading-[24px]">
                  Cash price summary
                </div>
                <div className="text-[16px] leading-[24px]">
                  {solarInquiryData && solarInquiryData.batteryBrand
                    ? solarInquiryData.batteryBrand
                    : `---`}
                </div>
              </li>
              <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
                <div className="flex justify-between items-center">
                  <p>Gross installed cost</p>{' '}
                  <span>
                    $
                    {solarInquiryData && solarInquiryData.batteryGrossInstalledCost
                      ? parseInt(solarInquiryData.batteryGrossInstalledCost).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
              </li>
              <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
                <div className="flex justify-between items-center">
                  <p>Federal incentives</p>{' '}
                  <span className="text-[#00330E]">
                    $
                    {solarInquiryData && solarInquiryData.batteryFedralIncentives
                      ? parseInt(solarInquiryData.batteryFedralIncentives).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
                <div className="flex justify-between items-center py-[8px]">
                  <p>Local & state incentives</p>{' '}
                  <span className="text-[#00330E]">
                    {solarInquiryData && solarInquiryData.batteryLocalAndStateIncentives
                      ? solarInquiryData.batteryLocalAndStateIncentives
                      : `Varies`}
                  </span>
                </div>
                {/* <div className="flex justify-between items-center">
                  <p>Daylight Cashback</p>{' '}
                  <span className="text-[#00330E]">
                    $
                    {solarInquiryData && solarInquiryData.batteryDaylightCashback
                      ? parseInt(solarInquiryData.batteryDaylightCashback).toLocaleString('en-US')
                      : 0}
                  </span>
                </div> */}
              </li>
              <li className="py-[16px] text-[16px] leading-[24px] text-primary">
                <div className="flex justify-between">
                  <p>Net battery cost</p>{' '}
                  <span className="text-[57px] leading-[64px]">
                    $
                    {solarInquiryData && solarInquiryData.batteryNetCost
                      ? parseInt(solarInquiryData.batteryNetCost).toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
                <div className="mt-[16px]">
                  <p className="text-[12px] leading-[16px] tracking-[0.1px]">
                    *If you choose to add on a home battery to your solar system, you may be able to
                    include the cost in the same loan.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="px-[16px] w-full text-primary ml-[8px] sm:mb-0 mb-[72px]">
          <span>
            <u
              id="step-11-see-more"
              onClick={toggleTooltip}
              className="text-[11px] font-aeonik-bold leading-[16px] tracking-[0.1px]">
              See more
            </u>
            <ReactTooltip
              id="custom-tooltip-for-step-11"
              anchorSelect="#step-11-see-more"
              className="!bg-primary !p-[15px] !shadow-lg"
              effect="solid"
              place="top-end"
              clickable={true}
              delayHide={100}
              isOpen={isTooltipVisible}
              openOnClick={true}
              opacity={1}>
              <div className="text-[12px] text-[#fff] md:max-w-[674px] max-w-[280px] m-auto">
                <div className="flex justify-between items-center mb-[5px]">
                  <span className="uppercase text-[12px] font-aeonik-bold leading-[16px]">
                    disclaimer
                  </span>
                  <span
                    className="[&>svg]:w-[20px] [&>svg]:h-[20px] cursor-pointer"
                    onClick={toggleTooltip}>
                    {/* <CloseCircularIcon stroke={'#fff'} /> */}
                    <AiOutlineCloseCircle size={24} className="!fill-white" />
                  </span>
                </div>
                <div className="text-[12px] leading-[16px] tracking=[0.1px] pr-[22px]">
                  The projected reward of up to $750 per year is based on historical data and
                  specific programs previously implemented in California and Texas markets. These
                  figures do not guarantee or predict future results. Participation opportunities
                  and potential earnings may vary greatly by region and are subject to changes in
                  program rules, regional energy usage, battery system size, and other factors.
                  Daylight provides this estimate as a broad guideline and does not make specific
                  claims or guarantees about how much an individual or entity can earn from
                  participating in the Community Energy Network with a battery system. Additionally,
                  Daylight makes no representations on the guarantee of power availability from your
                  home battery system in the case of power outages. Please consult with a Daylight
                  representative to understand how a battery will benefit you.
                </div>
              </div>
            </ReactTooltip>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Step11;
