import React from 'react';

const BatteryIcon = () => {
  return (
    <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33.5" r="33" fill="#15049E" />
      <g clipPath="url(#clip0_5_2)">
        <path
          d="M41.59 36.82V33.96H22.5V43.5H41.59V40.64H43.5V36.82H41.59Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M25.36 22.5H27.55C28.2699 22.5 28.9827 22.6426 29.6472 22.9196C30.3117 23.1966 30.9148 23.6025 31.4215 24.1139C31.9282 24.6253 32.3286 25.232 32.5996 25.899C32.8705 26.566 33.0066 27.2801 33 28H30.82C30.0992 28.0014 29.3853 27.8597 28.7197 27.5833C28.054 27.3069 27.4498 26.9012 26.942 26.3897C26.4342 25.8781 26.0329 25.271 25.7613 24.6033C25.4898 23.9356 25.3534 23.2207 25.36 22.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M35.19 28.91H33.01C33.01 27.4646 33.5842 26.0783 34.6063 25.0563C35.6284 24.0342 37.0146 23.46 38.46 23.46H40.64C40.64 24.9054 40.0658 26.2917 39.0437 27.3137C38.0217 28.3358 36.6354 28.91 35.19 28.91Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path d="M33 33.95V27.27" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M26.32 35.86V41.59" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M30.14 35.86V41.59" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M33.95 35.86V41.59" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_5_2">
          <rect width="24" height="24" fill="white" transform="translate(21 21)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BatteryIcon;
