import React, { useState } from 'react';
import logo from '../../../../assets/images/daylight-logo-long.png';
import Sidebar from './components/SideBar/Sidebar';
import HamburgerButton from '../../../HamburgerButton/HamburgerButton';

const StepperHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="flex justify-between items-center h-full">
      <div>
        <img src={logo} className="max-w-[125px]" alt="" />
      </div>
      <HamburgerButton isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} onClose={closeSidebar} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default StepperHeader;
