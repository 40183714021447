import React, { useContext } from 'react';
import './StepperProgress.scss';
import { StepperContext } from '../../../../provider/stepper.provider';

const StepperProgress = () => {
  const { stepsArray, activeStep, prevActiveStep } = useContext(StepperContext);
  const totalSteps = stepsArray.length;

  return (
    <div className="flex flex-row gap-1 bg-[#F0EEFC] ">
      {stepsArray.map(
        (step, index) =>
          index > 0 && (
            <div
              key={step.key}
              // style={{
              //   animationDuration: `${2}s`,
              //   animationDelay: `${2}s`,
              //   animationIterationCount: 'infinite'
              // }}
              className={`progress ease-in-out duration-[2000ms] transition-all ${
                index <= activeStep ? 'bg-primary active' : '!bg-[#D0CDEC]'
              } `}></div>
          )
      )}
    </div>
  );
};

export default StepperProgress;
