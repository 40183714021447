import React from 'react';
import { twMerge } from 'tailwind-merge';

const BlogCard = ({ title, description, className, imageProp, bottomImg }) => {
  return (
    <div
      className={twMerge(
        `relative rounded-lg min-h-[435px] overflow-hidden shadow-lg py-3`,
        className
      )}>
      {imageProp && imageProp.image && (
        <div
          id="blogcard-img-container"
          className={`min-h-[195px] flex justify-center items-center`}>
          <img src={imageProp.image} className={`h-auto ${imageProp.className}`} alt="" />
        </div>
      )}

      <div className="mx-[17px] mt-2">
        <h4 className="text-[16px] text-primary font-aeonik-bold leading-[24px] text-left mb-[8px]">
          {title}
        </h4>
        {/* <div className="text-[16px] text-primary leading-[24px] text-left">{description}</div> */}
        <div
          className="text-[16px] text-primary leading-[24px] text-left"
          dangerouslySetInnerHTML={{ __html: description }}></div>

        <img src={bottomImg} className="absolute left-0 right-0 bottom-0 top-auto" alt="" />
      </div>
    </div>
  );
};

export default BlogCard;
