import React from 'react';

const CrossIcon = () => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.486816"
        y="23.3709"
        width="32"
        height="2.66667"
        transform="rotate(-45 0.486816 23.3709)"
        fill="#15049E"
      />
      <rect
        x="2.37256"
        y="0.743484"
        width="32"
        height="2.66667"
        transform="rotate(45 2.37256 0.743484)"
        fill="#15049E"
      />
    </svg>
  );
};

export default CrossIcon;
