import React, { useContext, useEffect, useRef } from 'react';
import './Sidebar.scss';
import { StepperContext } from '../../../../../../provider/stepper.provider';
import { sidebarItems } from './SidebarItems';
import { SolarDataContext } from '../../../../../../provider/solarData.provider';
import { LeadContext } from '../../../../../../provider/lead.provider';

const Sidebar = ({ isOpen, toggleSidebar, onClose }) => {
  const { activeStep, setActiveStep } = useContext(StepperContext);
  const sidebarRef = useRef(null);

  const { authToken } = useContext(SolarDataContext);
  const {
    currentLeadStep,
    setCurrentLeadStep,
    leadId,
    addLeadStep,
    updateUserLead,
    generateLeadId,
    visitedSteps,
    addVisitedStep
  } = useContext(LeadContext);

  const trackStepsLeads = async (step) => {
    if (!visitedSteps.some((visitedStep) => visitedStep.step === step.step)) {
      await addLeadStep({ type: 'Mini website Sidebar item click' }, step.title);
      addVisitedStep(step);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="flex justify-between items-center border-b-[1px] border-solid border-primary pb-[24px]">
        <h4 className="text-primary text-[24px] leading-[32px]">Sections</h4>
        <div className="close-button" onClick={toggleSidebar}>
          <div className="bar bar1"></div>
          <div className="bar bar2"></div>
        </div>
      </div>

      <ul className="menu">
        {sidebarItems.map((step) => (
          <li
            key={step.step}
            onClick={async () => {
              setActiveStep(step.step);
              if (leadId) {
                await trackStepsLeads(step);
              }
            }}
            className={`${activeStep === step.step ? 'active' : ''}`}>
            <span>{step.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
