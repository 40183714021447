import CryptoJS from 'crypto-js';
import { encryptionSecretKey } from '../Constants';
export const validateEmail = (email) => {
  // Regular expression to check email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const calculatePMT = (rate, periods, presentValue, futureValue = 0, type = 0) => {
  // rate         - intrest rate value
  // periods      -  Number of years
  // presentValue - System Cost
  // futureValue  - Replace 0 with your futureValue value, if applicable
  // type         - Replace 0 with your type value, if applicable
  const denominator = Math.pow(1 + rate, periods) - 1;
  const numerator = rate * (futureValue + presentValue * Math.pow(1 + rate, periods));
  let payment = -numerator / denominator;

  if (type === 1) {
    payment /= 1 + rate;
  }

  return payment;
};

export const calculateSavings = (
  solarDegradationFactor,
  inflationRate,
  totalTimePeriod,
  electricityCost,
  annualUsage
) => {
  // inflationRate = 3.5;
  // console.log('solarDegradationFactor :', solarDegradationFactor);
  // console.log('inflationRate :', inflationRate);
  // console.log('totalTimePeriod :', totalTimePeriod);
  // console.log('electricityCost :', electricityCost);
  // console.log('annualUsage :', annualUsage);
  console.log('solarDegradationFactor :', solarDegradationFactor);
  console.log('inflationRate :', inflationRate);
  console.log('totalTimePeriod :', totalTimePeriod);
  console.log('electricityCost :', electricityCost);
  console.log('annualUsage :', annualUsage);

  const utilityRateArrayForTimePeriod = [];
  const solarOutputArrayForTimePeriod = [];
  const annualSavingsArrayForTimePeriod = [];

  for (let index = 0; index < totalTimePeriod; index++) {
    if (index === 0) {
      utilityRateArrayForTimePeriod.push(electricityCost);
      solarOutputArrayForTimePeriod.push(annualUsage);
      annualSavingsArrayForTimePeriod.push(electricityCost * annualUsage);
    } else {
      utilityRateArrayForTimePeriod.push(
        utilityRateArrayForTimePeriod[index - 1] * (1 + inflationRate / 100)
      );
      solarOutputArrayForTimePeriod.push(
        solarOutputArrayForTimePeriod[index - 1] * (1 + solarDegradationFactor / 100)
      );
      annualSavingsArrayForTimePeriod.push(
        utilityRateArrayForTimePeriod[index] * solarOutputArrayForTimePeriod[index]
      );
    }
  }

  // console.log('utilityRateArrayForTimePeriod :', utilityRateArrayForTimePeriod);
  // console.log('solarOutputArrayForTimePeriod :', solarOutputArrayForTimePeriod);
  // console.log('annualSavingsArrayForTimePeriod :', annualSavingsArrayForTimePeriod);
  // return annualSavingsArrayForTimePeriod.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue,
  //   0
  // );

  return {
    totalSavings: Number(
      annualSavingsArrayForTimePeriod
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        .toFixed(2)
    ),
    annualSavingsArrayForTimePeriod: annualSavingsArrayForTimePeriod.map((value) =>
      Number(value.toFixed(2))
    )
  };
};

export const calculateNumberOfPanels = (sizeSmoothing) => {
  const panelCount = (sizeSmoothing * 1000) / 400;
  // console.log('panelCount :', panelCount);
  return panelCount;
};

export const annualEnergyUsage = (electricityRate, monthlyEnergyCost) => {
  const annualEnergyUsage = Math.ceil(monthlyEnergyCost / electricityRate) * 12;
  return annualEnergyUsage;
};

export const encryptionAES = (inputValue, secretKey = encryptionSecretKey.secretKey) => {
  const encrypted = CryptoJS.AES.encrypt(inputValue, secretKey);
  return encrypted.toString();
};

export const decryptionAES = (encryptedValue, secretKey = encryptionSecretKey.secretKey) => {
  if (encryptedValue) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      return { success: true, data: decrypted };
    } catch (error) {
      return { success: false, error };
    }
  }
};

export const handleDecrypt = (encryptedValue) => {
  if (encryptedValue) {
    const result = decryptionAES(encryptedValue);

    if (result.success) {
      return result;
    } else {
      return (window.location.href = document.referrer);
    }
  }
};

export function redirectToExternalUrl(url, authToken, encryption = true, paramName = 'urlFrom') {
  let redirectUrl;
  if (encryption) {
    redirectUrl = `${url}?${paramName}=${encodeURIComponent(encryptionAES(authToken))}`;
  } else {
    redirectUrl = `${url}?${paramName}=${authToken}`;
  }

  return (window.location.href = redirectUrl);
}

export function getMonthAbbreviation(month) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return months[parseInt(month) - 1];
}

export function formatDate(inputDate, outputFormat) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const monthAbbreviation = getMonthAbbreviation(month);
  const yearAbbreviation = String(year).slice(-2);

  const formatTokens = {
    YYYY: year,
    MMM: monthAbbreviation,
    YY: yearAbbreviation,
    MM: month,
    DD: day,
    HH: hours,
    mm: minutes,
    ss: seconds
  };

  const tokenPattern = /YYYY|MMM|YY|MM|DD|HH|mm|ss/g;

  return outputFormat.replace(tokenPattern, (token) => formatTokens[token] || token);
}

export const calculateCompoundInterest = (
  principal,
  annualRate,
  years,
  compoundedTimesPerYear,
  newAnnualRate
) => {
  const n = compoundedTimesPerYear;
  const r = annualRate / n;
  const t = years;
  const newR = newAnnualRate / n;

  // Calculate the final amount before the rate increase
  const amountBeforeIncrease = principal * Math.pow(1 + r, n * t);

  // Calculate the final amount after the rate increase
  const amountAfterIncrease = amountBeforeIncrease * Math.pow(1 + newR, n * t);

  // Calculate the compound interest
  const compoundInterest = amountAfterIncrease - principal;

  return compoundInterest;
};

// const principalAmount = 10000; // Initial principal amount
// const annualInterestRateBeforeIncrease = 0.05; // 5% annual interest rate
// const years = 30; // Number of years
// const compoundedTimesPerYear = 1; // Compounded annually
// const newAnnualInterestRate = 0.0825; // 8.25% annual interest rate after the increase
// const averageAnnualInterest = 122; // New average annual interest

// // Calculate the initial amount that results in an average of 122 after 30 years
// const initialAmount = principalAmount / Math.pow(1 + annualInterestRateBeforeIncrease, years);

// // Calculate the new principal amount after the increase in interest rate
// const newPrincipalAmount = initialAmount / Math.pow(1 + newAnnualInterestRate, years);

// // Calculate the compound interest
// const compoundInterest = calculateCompoundInterest(
//   newPrincipalAmount,
//   annualInterestRateBeforeIncrease,
//   years,
//   compoundedTimesPerYear,
//   newAnnualInterestRate
// );

// console.log('Compound Interest after 30 years with increased rate:', compoundInterest.toFixed(2));
