import React from 'react';
import crossDiskShape from '../../../assets/images/cross_disk_shape.png';
import globeImg from '../../../assets/images/card-img-globe.png';
import controlCrossImg from '../../../assets/images/card-img-cross.png';
import perMonthImg from '../../../assets/images/200_per_month.png';
import arrowWithPercentage from '../../../assets/images/arrow_with_percentage.png';
import shapeBottomImg from '../../../assets/images/bottom_shape_img.png';
import BlogCard from '../../Card/BlogCard/BlogCard';

const Step5 = () => {
  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={crossDiskShape} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div className="relative">
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">How can solar help you?</p>

        <div className="grid lg:grid-cols-4 lg:gap-4 md:grid-cols-2 md:gap-2 grid-cols-1 gap-2 sm:mb-0 mb-[72px]">
          <BlogCard
            title={'Control'}
            description={
              'Solar energy allows you to become your own energy producer. You have full control over the power your home generates and uses, eliminating your dependence on traditional utility companies. No more worrying about unexpected price hikes or disruptions in service.'
            }
            className={'bg-secondary rounded-b-2xl xl:min-h-[470px]'}
            imageProp={{ image: controlCrossImg, className: 'w-full lg:h-[170px] h-auto' }}
          />
          <BlogCard
            title={'Savings & Stability'}
            description={
              'Solar offers considerable savings on your energy costs. Instead of a variable bill, solar is a fixed, stable monthly payment. Once your system is paid off, your energy produced is free. In most areas, you can even sell any excess power your system generates back to the grid, reducing costs further.'
            }
            className={'bg-secondary [&>*:first-child]:mx-[44px] rounded-b-2xl xl:min-h-[470px]'}
            imageProp={{ image: perMonthImg, className: 'w-full' }}
          />
          <BlogCard
            title={'Environmental Impact'}
            description={
              'Solar harvests clean, bountiful energy from the sun. Cheaper and cleaner energy is a win-win.'
            }
            className={'bg-secondary [&>*:first-child]:mx-[17px] rounded-b-2xl xl:min-h-[470px]'}
            imageProp={{ image: globeImg, className: 'w-full' }}
            bottomImg={shapeBottomImg}
          />
          <BlogCard
            title={'Home Value'}
            description={
              'Studies have consistently shown that homes with solar energy systems are viewed as more desirable, leading to higher resale values. On average, solar systems add an immediate 4% increase in the value of a home. Solar panels can be a significant selling point, making your property stand out in the market.'
            }
            className={'bg-secondary [&>*:first-child]:mx-[17px] rounded-b-2xl xl:min-h-[470px]'}
            imageProp={{ image: arrowWithPercentage, className: 'w-full' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Step5;
