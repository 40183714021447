import React from 'react';

const SolarPanelChargeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.5 11.95H2.5L6.3 1.5H17.7L21.5 11.95Z"
        stroke="#15049E"
        strokeWidth="1.9"
        strokeMiterlimit="10"
      />
      <path
        d="M5.25977 4.34961H9.57977H14.4198H18.7298"
        stroke="#15049E"
        strokeWidth="1.9"
        strokeMiterlimit="10"
      />
      <path
        d="M3.87988 8.15039H8.88988H15.1099H20.1199"
        stroke="#15049E"
        strokeWidth="1.9"
        strokeMiterlimit="10"
      />
      <path d="M10.1 1.5L8.19995 11.95" stroke="#15049E" strokeWidth="1.9" strokeMiterlimit="10" />
      <path
        d="M13.8999 1.5L15.7999 11.95"
        stroke="#15049E"
        strokeWidth="1.9"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0001 15.75L10.1001 18.6H12.0001V15.75Z"
        stroke="#15049E"
        strokeWidth="1.9"
        strokeMiterlimit="10"
      />
      <path
        d="M12 20.5004L13.9 17.6504H12V20.5004Z"
        stroke="#15049E"
        strokeWidth="1.9"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default SolarPanelChargeIcon;
