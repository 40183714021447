import React from 'react';

const DaylightLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="198"
      height="62"
      viewBox="0 0 198 62"
      fill="none">
      <path
        d="M0 53.0949V22.8351H9.86836C19.5172 22.8351 25.3762 28.8871 25.3762 37.965C25.3762 47.0429 19.5162 53.0949 9.86836 53.0949H0ZM3.83315 49.6362H9.86933C17.3594 49.6362 21.4564 45.0108 21.4564 37.965C21.4564 30.9192 17.3594 26.2938 9.86933 26.2938H3.83315V49.6362Z"
        fill="#15049E"
      />
      <path
        d="M50.7096 49.8526V53.0949H48.727C45.7316 53.0949 44.7177 51.8412 44.6734 49.6797C43.2637 51.6684 41.0164 53.3538 37.14 53.3538C32.2053 53.3538 28.8574 50.9334 28.8574 46.9126C28.8574 42.5035 31.9858 40.0397 37.8891 40.0397H44.4972V38.5271C44.4972 35.6741 42.4261 33.9452 38.902 33.9452C35.7303 33.9452 33.6149 35.4152 33.1749 37.6627H29.5622C30.0908 33.3396 33.6592 30.7898 39.0782 30.7898C44.8053 30.7898 48.1098 33.5994 48.1098 38.7435V48.3835C48.1098 49.5503 48.5508 49.8535 49.5637 49.8535H50.7096V49.8526ZM44.4972 43.0231H37.5357C34.3197 43.0231 32.5134 44.1899 32.5134 46.6972C32.5134 48.8587 34.4083 50.3287 37.4038 50.3287C41.8974 50.3287 44.4972 47.7779 44.4972 44.1039V43.0231Z"
        fill="#15049E"
      />
      <path
        d="M50.3994 31.0486H54.2326L61.1498 48.6424L67.8465 31.0486H71.6796L61.326 56.8124C59.9163 60.357 59.0786 61.7401 55.907 61.7401H51.5895V58.4978H54.8055C56.8766 58.4978 57.229 57.9792 58.0657 55.9045L59.1672 53.2243L50.3994 31.0486Z"
        fill="#15049E"
      />
      <path d="M74.3662 22.8351H77.9789V53.0949H74.3662V22.8351Z" fill="#15049E" />
      <path
        d="M82.4287 25.0402C82.4287 23.7006 83.4416 22.7493 84.7636 22.7493C86.0857 22.7493 87.0986 23.7006 87.0986 25.0402C87.0986 26.3799 86.0857 27.3312 84.7636 27.3312C83.4416 27.3312 82.4287 26.3799 82.4287 25.0402ZM82.913 31.0487H86.5257V53.095H82.913V31.0487Z"
        fill="#15049E"
      />
      <path
        d="M109.655 31.0486H112.783V52.0141C112.783 58.3683 109.347 61.9999 101.637 61.9999C95.9098 61.9999 91.9004 59.3631 91.3275 54.5214H95.0288C95.646 57.2876 98.1571 58.8445 101.902 58.8445C106.616 58.8445 109.171 56.6829 109.171 51.8847V49.2914C107.673 51.7987 105.251 53.3547 101.462 53.3547C95.2493 53.3547 90.668 49.2479 90.668 42.0727C90.668 35.3292 95.2502 30.7907 101.462 30.7907C105.251 30.7907 107.762 32.3901 109.171 34.854L109.656 31.0496L109.655 31.0486ZM109.214 42.1577C109.214 37.316 106.218 33.9442 101.812 33.9442C97.4061 33.9442 94.3663 37.2725 94.3663 42.0708C94.3663 46.8691 97.3618 50.1974 101.812 50.1974C106.262 50.1974 109.214 46.8691 109.214 42.1568V42.1577Z"
        fill="#15049E"
      />
      <path
        d="M137.851 40.8183V53.0951H134.239V41.0346C134.239 36.4527 131.991 34.0323 128.115 34.0323C123.929 34.0323 121.329 37.1017 121.329 42.0729V53.096H117.717V22.8353H121.329V34.6794C122.651 32.6048 124.942 30.789 128.731 30.789C133.753 30.789 137.851 33.5127 137.851 40.8183Z"
        fill="#15049E"
      />
      <path
        d="M144.149 34.2911H140.096V31.0487H144.149V24.8674H147.762V31.0487H153.578V34.2911H147.762V47.3028C147.762 49.1611 148.423 49.8536 150.362 49.8536H153.93V53.0959H150.009C145.692 53.0959 144.149 51.2367 144.149 47.3463V34.292V34.2911Z"
        fill="#15049E"
      />
      <path
        d="M197.16 17.6589H180.449L192.153 5.99715L190.852 4.72932L179.155 16.3845V0H177.156V16.3845L165.473 4.74255L163.971 5.98393L175.871 17.6589H159.15V19.6202H175.865L163.97 31.1119L165.472 32.3533L177.156 20.888V37.2866H179.155V20.888L190.854 32.3665L192.154 31.0987L180.456 19.6202H197.16V17.6589Z"
        fill="#15049E"
      />
    </svg>
  );
};

export default DaylightLogo;
