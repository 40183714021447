import React, { useContext, useEffect } from 'react';
import triangleImage from '../../../assets/images/triangle_shape.png';
import groupImg from '../../../assets/images/arrow_bulb.png';
import EnergyBill from '../../Charts/EnergyBill';
import rates from './rates.json';
import { UtilityContext } from '../../../provider/utility.provider';
import { SolarDataContext } from '../../../provider/solarData.provider';

const Step4 = () => {
  const { utilityRateHistoryData } = useContext(UtilityContext);
  const { solarInquiryData } = useContext(SolarDataContext);

  useEffect(() => {
    console.log('utilityRateHistoryData :', utilityRateHistoryData);
  }, [utilityRateHistoryData]);

  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={triangleImage} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">
          Utility electric rate increases in
          {solarInquiryData &&
          solarInquiryData.customerAddress &&
          solarInquiryData.customerAddress.state ? (
            <> {solarInquiryData.customerAddress.state} </>
          ) : (
            ' your area '
          )}
          over time
        </p>
      </div>

      <div className="flex flex-wrap gap-4 justify-between relative w-full">
        <div className="bg-[#F1ECA5] md:w-[25%] w-full p-[16px] flex-col items-start border-[1px] border-solid border-primary">
          <div className="my-[20px]">
            <div>
              <img src={groupImg} className="max-w-[95px]" alt="" />
            </div>
            <div className="my-[20px]">
              <p className="text-[16px] text-primary leading-[24px]">Cost of energy</p>
            </div>
            <div>
              <p className="text-[32px] text-primary leading-[32px]">
                Utility rates have only gone up over time. <br />
                <br /> With Daylight, say goodbye to your utility.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white p-[16px] md:w-[72%] w-full border-[1px] border-solid border-primary sm:mb-0 mb-[72px]">
          <div>
            <p className="text-[16px] leading-[24px] text-primary">
              Without action, the utility will continue to charge you more every year.
            </p>
          </div>
          <div className="text-center max-h-[375px] h-[375px] mt-[16px] text-primary flex items-center justify-center">
            {utilityRateHistoryData && utilityRateHistoryData.data.utilityRateHistory.length ? (
              <EnergyBill
                labels={utilityRateHistoryData.data.utilityRateHistory.map((item) => item.date)}
                datasets={[
                  {
                    label: 'Rate',
                    data: utilityRateHistoryData.data.utilityRateHistory.map((item) => ({
                      x: item.date,
                      y: item.rate
                    }))
                  }
                ]}
              />
            ) : (
              <span>No data Found</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
