import React, { useContext } from 'react';
import bgShape8 from '../../../assets/images/bg_shape_8.png';
import solar from '../../../assets/images/solar.jpg';
import { SolarDataContext } from '../../../provider/solarData.provider';

const Step8 = () => {
  const { solarInquiryData } = useContext(SolarDataContext);

  return (
    <div className="pt-[40px] pb-[85px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={bgShape8} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">
          Home power system design
        </p>
      </div>

      <div className="flex flex-wrap gap-4 justify-between relative w-full">
        <div className="bg-[#F1ECA5] w-full md:w-[48%] lg:w-[24%] p-[16px] flex-col items-start min-h-[474px]">
          <div>
            <div className="border-solid border-[#F1D972] border-b-[1px] pb-[16px]">
              <p className="text-[16px] text-primary leading-[24px]">System size</p>
              <span className="text-[24px] text-primary leading-[32px]">
                {solarInquiryData && solarInquiryData.systemSize
                  ? `${solarInquiryData.systemSize}`
                  : `0`}{' '}
                kW
              </span>
            </div>
            <div className="border-solid border-[#F1D972] border-b-[1px] py-[16px]">
              <p className="text-[16px] text-primary leading-[24px]">Panel type</p>
              <span className="text-[24px] text-primary leading-[32px]">
                {solarInquiryData && solarInquiryData.panelType
                  ? `${solarInquiryData.panelType}`
                  : `---`}
              </span>
            </div>
          </div>
          <div className="border-solid border-[#F1D972] border-b-[1px] py-[16px]">
            <p className="text-[16px] text-primary leading-[24px]">Inverter</p>
            <span className="text-[24px] text-primary leading-[32px]">
              {solarInquiryData && solarInquiryData.Inverter
                ? `${solarInquiryData.Inverter}`
                : `---`}
            </span>
          </div>
          <div className="py-[16px]">
            <p className="text-[16px] text-primary leading-[24px] my-2">Future add-ons</p>
            <div className="flex text-[#D3B01A] items-center">
              <div className="text-[24px] leading-[32px]  w-[159px]">
                <span>Home Battery</span>
                {/* <span>Heat Pump</span>
                <span>EV Charger</span> */}
              </div>
              <div className="text-[11px] leading-[16px] font-aeonik-bold">
                <span>Energy Independence</span>
                {/* <span>Ultra Efficient HVAC</span>
                <span>Low Cost Transportation</span> */}
              </div>
            </div>
            <div className="flex text-[#D3B01A] items-center">
              <div className="text-[24px] leading-[32px] w-[159px]">
                {/* <span>Home Battery</span> */}
                <span>Heat Pump</span>
                {/* <span>EV Charger</span> */}
              </div>
              <div className="text-[11px] leading-[16px] font-aeonik-bold ">
                {/* <span>Energy Independence</span> */}
                <span>Ultra Efficient HVAC</span>
                {/* <span>Low Cost Transportation</span> */}
              </div>
            </div>
            <div className="flex text-[#D3B01A] items-center">
              <div className="text-[24px] leading-[32px] w-[159px]">
                {/* <span>Home Battery</span>
                <span>Heat Pump</span> */}
                <span>EV Charger</span>
              </div>
              <div className="text-[11px] leading-[16px] font-aeonik-bold">
                {/* <span>Energy Independence</span>
                <span>Ultra Efficient HVAC</span> */}
                <span>Low Cost Transportation</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white w-full md:w-[48%] lg:w-[24%] p-[16px] flex-col border-[1px] border-solid border-primary min-h-[474px]">
          <div>
            <p className="text-primary text-[16px] leading-[24px]">Solar offset amount</p>
            <div className="my-[12px] bg-quaternary flex justify-center items-center h-[177px]">
              <p className="text-primary text-[56px] leading-[24px]">
                {solarInquiryData && solarInquiryData.solarOffSetAmount
                  ? `${parseFloat(
                      parseFloat(solarInquiryData.solarOffSetAmount).toFixed(1)
                    ).toLocaleString('en-US')}`
                  : `0`}
                %
              </p>
            </div>
          </div>
          <div className="h-[177px]">
            <p className="text-primary text-[16px] leading-[24px]">Total $ saved over 30 years</p>
            <div className="my-[12px] bg-quaternary flex justify-center items-center h-[181px]">
              <p className="text-primary text-[56px] leading-[24px]">
                $
                {solarInquiryData && solarInquiryData.totalSavingOver30Years
                  ? `${parseInt(solarInquiryData.totalSavingOver30Years).toLocaleString('en-US')}`
                  : `0`}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white p-[16px] lg:w-[48%] w-full border-[1px] border-solid border-primary min-h-[474px] sm:mb-0 mb-[72px]">
          <div className="bg-quaternary text-center max-h-[442px] h-[442px] text-primary flex items-center justify-center">
            {solarInquiryData && solarInquiryData.solarHomeImgUrl ? (
              <img
                src={solarInquiryData.solarHomeImgUrl}
                className="w-full h-full object-fill"
                alt="solarHome"
              />
            ) : (
              // <img src={solar} className="w-full h-full object-fill" alt="solarHome" />
              // <img src={solar} className="w-full h-full object-cover" alt="solarHome" />
              <p>Image of Home with Irradiance and solar Panels</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step8;
