import React from 'react';

const CloseCircularIcon = ({ stroke = '#15049E' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2817_30652)">
        <path
          d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.94824 16.0518L16.0517 7.94831"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.94824 7.94824L16.0517 16.0517"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2817_30652">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseCircularIcon;
