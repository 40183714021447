import React from 'react';

const CameraIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
      <path
        d="M22.5 1.32031H1.5V14.6803H22.5V1.32031Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.0002 11.8207C14.1099 11.8207 15.8202 10.1104 15.8202 8.00066C15.8202 5.89094 14.1099 4.18066 12.0002 4.18066C9.89045 4.18066 8.18018 5.89094 8.18018 8.00066C8.18018 10.1104 9.89045 11.8207 12.0002 11.8207Z"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.35986 5.14062H6.26986"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
      <path d="M17.73 10.8604H19.64" stroke="#15049E" strokeWidth="1.91" strokeMiterlimit="10" />
      <path
        d="M4.35986 5.14062H6.26986"
        stroke="#15049E"
        strokeWidth="1.91"
        strokeMiterlimit="10"
      />
      <path d="M17.73 10.8604H19.64" stroke="#020202" strokeWidth="1.91" strokeMiterlimit="10" />
    </svg>
  );
};

export default CameraIcon;
