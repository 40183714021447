import { createContext, useEffect, useState } from 'react';
import {
  INTEGRATIONS,
  ME,
  MONTHLY_AVERAGE_ELECTRICITY_COST_QUERY,
  UTILITY_HISTORY_RATE_QUERY,
  UTILITY_MONTHLY_DATA_QUERY,
  UTILITY_RATE_HISTORICAL_ANNUAL_QUERY
} from '../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';

export const UtilityContext = createContext();

export const UtilityProvider = ({ children }) => {
  const [ownerId, setOwnerId] = useState('');
  const [urjanetIntegrationId, setUrjanetIntegrationId] = useState('');
  const [urjanet, setUrjanet] = useState(null);
  const [utilityRateHistoryData, setUtilityRateHistoryData] = useState();
  const [monthlyAverageElectricityCostData, setMonthlyAverageElectricityCostData] = useState();
  const [utilityMonthlyData, setUtilityMonthlyData] = useState();
  const [utilityRateHistoricalAnnualData, setUtilityRateHistoricalAnnualData] = useState();

  const [fetchUtilityHistoryRateQuery, { loading: utilityLoading, data: utilityData }] =
    useLazyQuery(UTILITY_HISTORY_RATE_QUERY);

  const [
    fetchUtilityRateHistoricalAnnualQuery,
    { loading: utilityRateHistoricalAnnualQueryLoading, data: utilityRateHistoricalAnnualQueryData }
  ] = useLazyQuery(UTILITY_RATE_HISTORICAL_ANNUAL_QUERY);

  const [
    fetchMonthlyAverageElectricityCostQuery,
    {
      loading: monthlyAverageElectricityCostQueryLoading,
      data: monthlyAverageElectricityCostQueryData
    }
  ] = useLazyQuery(MONTHLY_AVERAGE_ELECTRICITY_COST_QUERY);

  const [
    fetchUtilityMonthlyDataQuery,
    { loading: utilityMonthlyDataQueryLoading, data: utilityMonthlyDataQueryData }
  ] = useLazyQuery(UTILITY_MONTHLY_DATA_QUERY);

  const [fetchMeData, { loading: meLoading, data: meData }] = useLazyQuery(ME);
  const [fetchIntegrationData, { loading: integrationLoading, data: integrationData }] =
    useLazyQuery(INTEGRATIONS);

  const fetchUtilityHistoryRate = async (stateCode) => {
    try {
      const utilityRateHistoryRes = await fetchUtilityHistoryRateQuery({
        variables: {
          stateCode
        }
      });
      console.log('response :', utilityRateHistoryRes);
      setUtilityRateHistoryData(utilityRateHistoryRes);
      return utilityRateHistoryRes;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUtilityRateHistoricalAnnual = async (payload) => {
    try {
      const utilityRateHistoricalAnnualRes = await fetchUtilityRateHistoricalAnnualQuery(payload);
      console.log('response :', utilityRateHistoricalAnnualRes);
      setUtilityRateHistoricalAnnualData(utilityRateHistoricalAnnualRes);
      return utilityRateHistoricalAnnualRes;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMonthlyAverageElectricityCost = async (payload) => {
    try {
      // payload
      // {
      //   context: {
      //     headers: {
      //       Authorization: `Bearer ${authToken}`
      //     }
      //   }
      // }
      const monthlyAverageElectricityCostRes = await fetchMonthlyAverageElectricityCostQuery(
        payload
      );
      console.log('monthlyAverageElectricityCostRes :', monthlyAverageElectricityCostRes);
      console.log(
        'monthlyAverageElectricityCostResData :',
        monthlyAverageElectricityCostRes.data.monthlyAverageElectricityCost
      );
      setMonthlyAverageElectricityCostData(
        monthlyAverageElectricityCostRes.data.monthlyAverageElectricityCost
      );
      return monthlyAverageElectricityCostRes.data.monthlyAverageElectricityCost;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUtilityMonthlyData = async (payload) => {
    try {
      // payload
      // {
      //   context: {
      //     headers: {
      //       Authorization: `Bearer ${authToken}`
      //     }
      //   }
      // }
      const utilityMonthlyDataRes = await fetchUtilityMonthlyDataQuery(payload);
      console.log('utilityMonthlyDataRes :', utilityMonthlyDataRes);
      console.log('utilityMonthlyDataResData :', utilityMonthlyDataRes.data.utilityMonthlyData);
      setUtilityMonthlyData(utilityMonthlyDataRes.data.utilityMonthlyData);
      return utilityMonthlyDataRes.data.utilityMonthlyData;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOwnerId = async (authToken) => {
    try {
      const meRes = await fetchMeData({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });

      if (meRes) {
        console.log('me data :', meRes);
        setOwnerId(meRes.data.me.id);

        if (meRes.data.me.accounts && meRes.data.me.accounts.length) {
          const credentialStatus = meRes.data.me.accounts[0].credential.status;
          setUrjanet(meRes.data.me.accounts[0].credential);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchIntegrationIds = async (authToken) => {
    try {
      const integrationIdsRes = await fetchIntegrationData({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });
      if (integrationIdsRes) {
        const urjanetIntegrationId =
          integrationIdsRes.data &&
          integrationIdsRes.data.integrations &&
          integrationIdsRes.data.integrations.edges.filter((e) => e.node.name === 'Urjanet');

        if (urjanetIntegrationId) {
          setUrjanetIntegrationId(urjanetIntegrationId[0].node.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUtilityData = async (authToken) => {
    try {
      if (authToken) {
        // console.log('authToken :', authToken);
        // setRequestTokenResponse(authToken);

        const ownerIdPromise = fetchOwnerId(authToken);
        const integrationIdsPromise = fetchIntegrationIds(authToken);

        // Wait for both promises to resolve
        await Promise.all([ownerIdPromise, integrationIdsPromise]);
      }
    } catch (error) {
      // Handle any errors
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchUtilityHistoryRate('NJ');
  // }, []);

  return (
    <UtilityContext.Provider
      value={{
        ownerId,
        urjanetIntegrationId,
        urjanet,
        utilityRateHistoryData,
        utilityMonthlyData,
        monthlyAverageElectricityCostData,
        utilityRateHistoricalAnnualData,
        setUtilityRateHistoryData,
        fetchUtilityHistoryRate,
        fetchMonthlyAverageElectricityCost,
        fetchUtilityMonthlyData,
        fetchUtilityRateHistoricalAnnual,
        fetchUtilityData
      }}>
      {children}
    </UtilityContext.Provider>
  );
};
