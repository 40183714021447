import { Route, Routes, useLocation, useParams, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import PageNotFound from './pages/PageNotFound';
import { Toaster } from 'react-hot-toast';
import 'react-tooltip/dist/react-tooltip.css';
import './Globals.scss';
import 'chart.js/auto';
import { Chart as ChartJS } from 'chart.js';
import 'chartjs-adapter-date-fns';
import Modal from './components/Modal/Modal';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  DECODE_NOUNCE_QUERY,
  OPEN_SOLAR_PROJECT_DATA_QUERY,
  SOLAR_CONFIGURATION,
  SOLAR_PROPOSAL_QUERY,
  UTILITY_HISTORY_RATE_QUERY
} from './GraphQL/Queries';
import { useContext, useEffect, useState } from 'react';
import {
  annualEnergyUsage,
  calculateNumberOfPanels,
  calculateSavings,
  redirectToExternalUrl
} from './utils/utils';
import { SolarDataContext } from './provider/solarData.provider';
import { UtilityContext } from './provider/utility.provider';
import Loader from './components/Loader/Loader';
import Cookies from 'js-cookie';
import { cookieAttributes } from './Constants';

ChartJS.defaults.font.family = 'Aeonik Regular';
ChartJS.defaults.color = '#15049E';
ChartJS.defaults.backgroundColor = '#15049E';
ChartJS.defaults.borderColor = '#15049E';
ChartJS.defaults.scales.linear.grid = { color: '#D0CDEC' };
ChartJS.defaults.scales.linear.border = { color: '#D0CDEC' };
ChartJS.defaults.scales.time.grid = { display: false };
ChartJS.defaults.scales.time.border = { color: '#D0CDEC' };
ChartJS.defaults.scales.category.grid = { display: false };
ChartJS.defaults.scales.category.border = { color: '#D0CDEC' };
ChartJS.defaults.plugins.colors.enabled = false;
ChartJS.defaults.plugins.legend.display = false;

const authUrl = process.env.REACT_APP_AUTH_URL;

function App() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // const authToken = params.get('authToken');
  const authCode = params.get('authToken');
  const accessToken = Cookies.get('daylight_access_token');
  const navigate = useNavigate();

  // const authToken = Cookies.get('daylight_access_token');
  // const urlFrom = window.location.href;
  // const urlFrom =
  //   window.location.protocol +
  //   '//' +
  //   window.location.host +
  //   (window.location.port ? ':' + window.location.port : '') +
  //   window.location.pathname;
  const urlFrom = window.location.protocol + '//' + window.location.host + window.location.pathname;

  const [email, setEmail] = useState('abhishek@mailinator.com');
  const [isLoading, setIsLoading] = useState(false);

  // Contexts
  const {
    solarInquiryData,
    setSolarInquiryData,
    solarConfiguration,
    setSolarConfiguration,
    setAuthToken,
    authToken
  } = useContext(SolarDataContext);
  const {
    fetchUtilityHistoryRate,
    fetchMonthlyAverageElectricityCost,
    fetchUtilityMonthlyData,
    fetchUtilityRateHistoricalAnnual,
    fetchUtilityData
  } = useContext(UtilityContext);

  // const FINANCE_RATE = parseFloat(solarConfiguration.financeRate); // 2.99%;
  // const TERM_IN_YEARS = parseInt(solarConfiguration.termInYears);
  // const PER_BATTERY_COST = parseFloat(solarConfiguration.batteryCostPerBattery);
  // const PER_BATTERY_PAYMENT = parseFloat(solarConfiguration.batteryPaymentPerBattery);
  // const BATTERY_FEDERAL_INCENTIVE = parseFloat(solarConfiguration.batteryItcRate); // 30%
  // const PER_BATTERY_CASHBACK_RATE = parseFloat(solarConfiguration.daylightCashbackRate); // 12.50%

  // const INFLATION_RATE_PERCENTAGE =
  //   solarConfiguration && parseFloat(solarConfiguration.inflationRate); // increase by 3% per year
  // const SOLAR_DEGRADATION_FACTOR_PERCENTAGE =
  //   solarConfiguration && parseFloat(solarConfiguration.solarDegradationFactor); // decrease 0.05% per year
  // // const TOTAL_YEARS = parseInt(solarConfiguration.termInYears);
  // const TOTAL_YEARS = 30;

  const [
    fetchSolarProposalData,
    { loading: solarProposalQueryLoading, data: solarProposalQueryData }
  ] = useLazyQuery(SOLAR_PROPOSAL_QUERY);
  const [solarConfigDataQuery, { loading: solarConfigLoading, data: solarConfigData }] =
    useLazyQuery(SOLAR_CONFIGURATION);

  const [openSolarProjectQuery, { loading: openSolarProjectQueryLoading }] = useLazyQuery(
    OPEN_SOLAR_PROJECT_DATA_QUERY
  );

  const [decodeNounceQuery, { loading: decodeNounceQueryLoading }] =
    useLazyQuery(DECODE_NOUNCE_QUERY);

  const fetchOpenSolarProject = async () => {
    try {
      const openSolarProjectRes = await openSolarProjectQuery({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });

      console.log('openSolarProjectRes :', openSolarProjectRes);
      return openSolarProjectRes;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSolarInquiry = async () => {
    try {
      const solarInquiryRes = await fetchSolarProposalData({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });
      if (solarInquiryRes) {
        // console.log(
        //   'solarInquiryRes :',
        //   solarInquiryRes.errors[0].message === 'Error decoding signature'
        // );
        if (
          solarInquiryRes.errors &&
          solarInquiryRes.errors[0].message === 'Error decoding signature'
        ) {
          redirectToExternalUrl(authUrl, urlFrom);
        } else {
          const solarInquiryResData = { ...solarInquiryRes.data.solarProposal[0] };

          if (Object.keys(solarInquiryResData).length) {
            // console.log('solarInquiryResData :', solarInquiryResData);
          }
        }
      }
      return solarInquiryRes.data.solarProposal[0];
    } catch (error) {
      console.log(error);
    }
  };

  const checkProposalIsExist = async () => {
    const getSolarInquiry = await fetchSolarInquiry();
    // console.log('getSolarInquiry :', getSolarInquiry);
    console.log('getSolarInquiry :', getSolarInquiry);
    if (getSolarInquiry) {
      await fetchUtilityHistoryRate(getSolarInquiry.customerAddress.state);
      await fetchUtilityRateHistoricalAnnual({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        },
        variables: {
          state: getSolarInquiry.customerAddress.state
        }
      });
      setSolarInquiryData({
        ...solarInquiryData,
        id: getSolarInquiry.id,
        customerAddress: getSolarInquiry.customerAddress,
        customerName: getSolarInquiry.customDesignFor,
        createdBy: getSolarInquiry.customDesignForCreatedBy,
        utility: {
          ...solarInquiryData.utility,
          avgMonthlyElectricBill: getSolarInquiry.avgMonthlyElectricityBill,
          avgAnnualElectricBill: getSolarInquiry.avgAnnualElectricityBill,
          estimatedAnnualIncrease: getSolarInquiry.estimatedAnnualIncrease,
          costOfNothing30Years: getSolarInquiry.thirtyYearCostOfDoingNothing,
          totalDue: getSolarInquiry.totalDue
        },
        systemSize: getSolarInquiry.systemSize,
        panelType: getSolarInquiry.panelType,
        Inverter: getSolarInquiry.inverter,
        solarOffSetAmount: getSolarInquiry.solarOffset,
        totalSavingOver30Years: getSolarInquiry.totalSavingOver30Years,
        solarHomeImgUrl: getSolarInquiry.imgOfHomeWithIrradanceAndSolarPannels,
        newMonthEnergyPayment: getSolarInquiry.newMonthEnergyPayment,
        monthlyDaylighCashback: getSolarInquiry.perMonthCashback,
        newMonthPaymentCashback: getSolarInquiry.newMonthPaymentCashback,
        cashPriceSummery: getSolarInquiry.cashPriceSummary,
        year1SystemProduction: getSolarInquiry.year1SystemProduction,
        numberOfPanels: getSolarInquiry.numberOfPanels,
        grossSystemCost: getSolarInquiry.grossSystemCost,
        incentives: getSolarInquiry.federalIncentives,
        daylightCashback: getSolarInquiry.daylightCashback,
        netSystemCashCost: getSolarInquiry.netSystemCashCost,
        batteryQuantity: getSolarInquiry.batteryQuantityMin,
        batteryBrand: getSolarInquiry.batteryBrand,
        batteryGrossInstalledCost: getSolarInquiry.grossInstalledCost,
        batteryFedralIncentives: getSolarInquiry.localStateIncentives,
        batteryLocalAndStateIncentives: getSolarInquiry.federalLocalStateIncentives,
        batteryDaylightCashback: getSolarInquiry.batteryDaylightCashback,
        batteryNetCost: getSolarInquiry.netBatteryCost,
        solarProposalPdf:
          getSolarInquiry.solarProposalPdf && getSolarInquiry.solarProposalPdf.length
            ? getSolarInquiry.solarProposalPdf[0].pdf
            : null
      });
    }
    return getSolarInquiry;
    // setSolarInquiryData(getSolarInquiry);
  };

  const fetchAccessToken = async (nonce) => {
    try {
      const decodeNounceRes = await decodeNounceQuery({
        variables: {
          nonce
        }
      });

      // console.log('decodeNounceRes :', decodeNounceRes);
      if (decodeNounceRes) {
        setAuthToken(decodeNounceRes.data.decodeNounce.token);
      }

      Cookies.set(
        'daylight_access_token',
        decodeNounceRes.data.decodeNounce.token,
        cookieAttributes
      );

      const currentUrl = window.location.href;
      console.log(currentUrl);
      const url = new URL(currentUrl);
      console.log(url);
      const path = url.pathname;

      navigate(path);
      return decodeNounceRes.data.decodeNounce;
    } catch (error) {
      // console.log('Dddd :', error);
      redirectToExternalUrl(authUrl, urlFrom);
    }
  };

  useEffect(() => {
    if (accessToken) {
      setAuthToken(accessToken);
    }
  }, []);

  useEffect(() => {
    if (!accessToken) {
      if (!authCode) {
        redirectToExternalUrl(authUrl, urlFrom);
      } else {
        fetchAccessToken(authCode);
      }
    }
  }, [authCode]);

  useEffect(() => {
    setIsLoading(true);
    if (authToken) {
      console.log(authToken);
      setAuthToken(authToken);

      const fetchSolarConfigData = async () => {
        try {
          const { data } = await solarConfigDataQuery();

          if (data) {
            setSolarConfiguration(data.solarConfiguration);
          }
          return data;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      };

      const authContext = {
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      };

      const fetchAllData = async () => {
        try {
          await Promise.all([
            fetchSolarConfigData(),
            checkProposalIsExist(email),
            fetchUtilityData(authToken)
          ]);

          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };

      fetchAllData();
      // fetchOpenSolarProject();
      fetchMonthlyAverageElectricityCost(authContext);
      fetchUtilityMonthlyData(authContext);
    }
  }, [authToken]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (!authCode) {
  //     redirectToExternalUrl(authUrl, urlFrom);
  //   } else {
  //     console.log(authToken);
  //     setAuthToken(authToken);

  //     const fetchSolarConfigData = async () => {
  //       try {
  //         const { data } = await solarConfigDataQuery();

  //         if (data) {
  //           setSolarConfiguration(data.solarConfiguration);
  //         }
  //         return data;
  //       } catch (error) {
  //         // eslint-disable-next-line no-console
  //         console.error(error);
  //       }
  //     };

  //     const authContext = {
  //       context: {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`
  //         }
  //       }
  //     };

  //     const fetchAllData = async () => {
  //       try {
  //         await Promise.all([
  //           fetchSolarConfigData(),
  //           checkProposalIsExist(email),
  //           fetchUtilityData(authToken)
  //         ]);

  //         setIsLoading(false);
  //       } catch (error) {
  //         console.error(error);
  //         setIsLoading(false);
  //       }
  //     };

  //     fetchAllData();
  //     fetchOpenSolarProject();
  //     fetchMonthlyAverageElectricityCost(authContext);
  //     fetchUtilityMonthlyData(authContext);
  //   }
  // }, []);

  useEffect(() => {
    console.log('solarInquiryData console :', solarInquiryData);

    if (solarInquiryData && solarInquiryData.sizeSmoothing) {
      const panelCount = calculateNumberOfPanels(parseFloat(solarInquiryData.sizeSmoothing));
      console.log('panelCount :', panelCount);

      console.log('solarConfiguration :', solarConfiguration);
    }
  }, [solarInquiryData]);

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div className="relative overflow-x-hidden">
        {isLoading && (
          <div className="fixed top-0 left-0 right-0 z-[9000] bottom-0 bg-white bg-opacity-100 overflow-hidden pointer-events-none">
            <Loader showLoaderOnly={solarConfigLoading} />
          </div>
        )}
        <div className="flex item-center justify-center h-screen bg-neutral ">
          <div className="m-auto bg-white w-full h-screen items-center">
            <Modal />
            <Routes>
              <Route path="/:state?" element={<Home />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
