import { gql } from '@apollo/client';

export const EMAIL_VERIFICATON = gql`
  mutation Mutation($input: VerifyOTPInput!) {
    verifyOTP(input: $input) {
      success
    }
  }
`;

export const GENERATE_OTP = gql`
  mutation GenerateOTP($input: GenerateOTPInput!) {
    generateOTP(input: $input) {
      success
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($input: VerifyOTPInput!) {
    verifyOTP(input: $input) {
      success
    }
  }
`;

export const REQUEST_AUTH_CHALLENGE = gql`
  mutation RequestAuthChallenge {
    requestAuthChallenge {
      authCode
    }
  }
`;

export const CREATE_NONCE_TOKEN_MUTATION = gql`
  mutation CreateNounceToken {
    createNounceToken {
      nounceToken {
        nounce
        isUsed
        id
      }
    }
  }
`;

export const REQUEST_TOKEN = gql`
  mutation RequestToken($input: RequestTokenInput!) {
    requestToken(input: $input) {
      token
      restToken
    }
  }
`;

export const SOLAR_INQUIRY = gql`
  mutation SolarInquiry($input: SolarInquiryInput!) {
    solarInquiry(input: $input) {
      solarInquiry {
        id
      }
    }
  }
`;

export const UPDATE_SOLAR_INQUIRY = gql`
  mutation UpdateSolarInquiry($input: UpdateSolarInquiryInput!) {
    updateSolarInquiry(input: $input) {
      solarInquiry {
        id
      }
    }
  }
`;

/**
 * Use to onBoard urjanet account
 * @param credentials: Object
 * { type: 'BASIC', clientID: selectedOption.providerId, password:String, username:String }
 * @param type: 'UTILITY'
 * @param ownerID: String
 * @param integrationID: String
 * @param name: String
 * @returns id
 */

export const ENROLL_ACCOUNT = gql`
  mutation EnrollAccount($input: EnrollAccountInput!) {
    enrollAccount(input: $input) {
      id
    }
  }
`;

/**
 * Use to Update urjanet credentials
 * @param credentialID: String
 * @param password: String
 * @param usename: String
 * @param updateToken: String
 * @returns success
 */
export const UPDATE_CREDENTIAL = gql`
  mutation UpdateCredential($input: UpdateCredentialsInput!) {
    updateCredential(input: $input) {
      success
    }
  }
`;

/**
 * Use to initializeSolarProposal
 * @input addressInput
 * @param email: String
 * @param address: String
 * @param city: String
 * @param state: String
 * @param zipCode: String
 * @param phoneNumber: String
 * @param familyType: enum['Single','Multi]
 * @param longitude: Float
 * @param latitude: Float
 * @param electricityCost: Float
 * @param avgMonthlyElectricityCost: Float
 * @param progress: String
 * ----solarproposal-----
 * @input solarProposal
 * @param earnCashbackUpTo: String
 * @param estSolarSystemSizeKw: Float
 * @param federalItc: String
 * @param savingOver25Years: String
 * @param sizeSmoothing: String
//  * @param systemSize: String
 * @param treesPlanted: Float
 * @param wattCost: String
 * @param systemCost: String
 * @param netSystemCost: String
 * @param batteryQuantity: Int
 * @param onHome: enum
 * @returns success
 */

export const INITIALIZE_SOLAR_PROPOSAL_MUTATION = gql`
  mutation InitializeSolarProposalMutation(
    $addressInput: CustomerAddressInput!
    $solarProposalInput: SolarProposalInput
  ) {
    initializeSolarProposal(address: $addressInput, solarProposal: $solarProposalInput) {
      success {
        id
        customerAddress {
          id
        }
      }
    }
  }
`;

/**
 * Use to initializeSolarProposal
 * @input addressInput
 * @param id: String
 * @param email: String
 * @param address: String
 * @param city: String
 * @param state: String
 * @param zipCode: String
 * @param phoneNumber: String
 * @param familyType: enum['Single','Multi]
 * @param longitude: Float
 * @param latitude: Float
 * @param electricityCost: Float
 * @param avgMonthlyElectricityCost: Float
 * @param progress: String
 * ----solarproposal-----
 * @input solarProposal
 * @param id: String
 * @param earnCashbackUpTo: String
 * @param estSolarSystemSizeKw: Float
 * @param federalItc: String
 * @param savingOver25Years: String
 * @param sizeSmoothing: String
//  * @param systemSize: String
 * @param treesPlanted: Float
 * @param wattCost: String
 * @param systemCost: String
 * @param netSystemCost: String
 * @param batteryQuantity: Int
 * @returns success
 */

export const UPDATE_SOLAR_PROPOSAL_MUTATION = gql`
  mutation UpdateSolarProposalMutation(
    $addressInput: UpdateCustomerAddressInput
    $solarProposalInput: UpdateSolarProposalInput
  ) {
    updateSolarProposal(address: $addressInput, solarProposal: $solarProposalInput) {
      success {
        customerAddress {
          address
          avgMonthlyElectricityCost
          city
          electricityCost
          email
          id
          latitude
          longitude
          phoneNumber
          progress
          state
          zipCode
        }
        batteryQuantity
        earnCashbackUpTo
        energyBillingSaving
        estSolarSystemSizeKw
        federalItc
        monthlyEnergyCost
        netSystemCost
        savingOver25Years
        sizeSmoothing
        systemCost
        systemSize
        treesPlanted
        wattCost
        id
      }
    }
  }
`;

/**
 * Use to create lead
 * @param type: 'SOLAR'
 * @param browserFingerprint: String
 * @param referrer: String
 * @param ipAddress: String
 * @returns id
 */

export const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      lead {
        id
        browserFingerprint
        referrer
      }
    }
  }
`;

/**
 * Use to add lead
 * @param data: JSONObject
 * @param leadId*: string
 * @param step: string
 * @returns id
 */

export const ADD_LEAD_STEP_MUTATION = gql`
  mutation AddLeadStep($input: AddLeadStepInput!) {
    addLeadStep(input: $input) {
      step {
        id
        step
        data
      }
    }
  }
`;

/**
 * Use to update lead for add user email after login
 * @param leadId*: string
 * @param markComplete: boolean
 * @returns id
 */

export const USER_LEAD_UPDATE_MUTATION = gql`
  mutation UserLeadUpdate($input: UserLeadUpdateInput!) {
    userLeadUpdate(input: $input) {
      lead {
        browser
        id
        ipAddress
        isCompleted
        osType
        type
      }
    }
  }
`;
