import React from 'react';
import bgShape7 from '../../../assets/images/bg-shape-7.png';
import solarHouse from '../../../assets/images/Solar-house.png';
import SolarHouse from '../components/SolarHouse/SolarHouse';

const Step7 = () => {
  return (
    <div className="pt-[40px] pb-[85px]">
      <div className="h-[80vh] absolute overflow-hidden flex justify-start items-end">
        <img
          src={bgShape7}
          className="xl:w-[50%] lg:w-[80%] w-[100%] ml-[10%] md:block hidden"
          alt=""
        />
      </div>
      {/* bottom-auto top-[34%] flex justify-start items-center  */}
      {/* <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[15px]">How solar works</p>
      </div> */}

      <div className="flex flex-wrap gap-4 relative w-full">
        <div className="w-full  md:w-[48%] p-[16px] flex-col items-start">
          <p className="text-[45px] leading-[52px] text-primary mb-[15px]">How solar works</p>
          <div className="my-[20px] max-w-[553px] font-aeonik-regular">
            <p className="text-primary leading-[24px] text-[16px]">
              During the day, when the sun is shining, your solar panels can often generate more
              electricity than your home uses. This is one of the many appealing aspects of
              installing solar panels on your roof - the ability to save money on energy costs and
              gain a measure of energy self-reliance.
            </p>
            <br />
            <p className="text-primary leading-[24px] text-[16px]">
              So, what happens to the surplus electricity you generate? Well, it&apos;s not wasted.
              Instead, your utility company keeps a record of it so you can use it when needed. This
              process is called &apos;net metering&apos;. Essentially, it works like a &apos;savings
              account&apos; for your surplus solar energy.
            </p>
            <br />
            <p className="text-primary leading-[24px] text-[16px]">
              Under a typical net metering policy, the utility company credits your extra
              electricity production at a 1:1 rate, meaning you get the full value for your surplus
              solar electricity. It&apos;s like depositing your excess solar energy into a
              &apos;bank&apos; that you can draw from later. So, with net metering, even when the
              sun isn&apos;t shining, you&apos;re still benefitting from the power it provides!
            </p>
            <br />
            <p className="text-primary leading-[24px] text-[16px]">
              Other utilities offer net billing credits in place of net metering. In net billing,
              the utility gives you a $ per kilowatt-hour bill credit on your utility bill for solar
              generation.
            </p>
          </div>
        </div>
        <div className="p-[16px] md:w-[48%] w-full sm:mb-0 mb-[72px]">
          <>
            <SolarHouse />
          </>

          {/* <img src={solarHouse} alt="" /> */}

          {/* <div>
            <p className="text-[16px] leading-[24px] text-primary">
              Without action, the utility will continue to charge you more every year.
            </p>
          </div>
          <div className="bg-quaternary text-center max-h-[375px] h-[375px] mt-[16px] text-primary flex items-center justify-center">
            <p>Chart</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Step7;
