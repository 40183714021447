import { createContext, useEffect, useState } from 'react';
import Step1 from '../components/Stepper/Steps/Step1';
import Step2 from '../components/Stepper/Steps/Step2';
import Step3 from '../components/Stepper/Steps/Step3';
import Step4 from '../components/Stepper/Steps/Step4';
import Step5 from '../components/Stepper/Steps/Step5';
import Step6 from '../components/Stepper/Steps/Step6';
import Step7 from '../components/Stepper/Steps/Step7';
import Step8 from '../components/Stepper/Steps/Step8';
import Step9 from '../components/Stepper/Steps/Step9';
import Step10 from '../components/Stepper/Steps/Step10';
import Step11 from '../components/Stepper/Steps/Step11';
import Step12 from '../components/Stepper/Steps/Step12';
import Step13 from '../components/Stepper/Steps/Step13';
import OpenSolarStep from '../components/Stepper/Steps/OpenSolarStep';

export const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
  const stepsArray = [
    <Step1 key="step1" />,
    <Step2 key="step2" />,
    <Step3 key="step3" />,
    <Step4 key="step4" />,
    <Step5 key="step5" />,
    <Step6 key="step6" />,
    <Step7 key="step7" />,
    <Step8 key="step8" />,
    <Step9 key="step9" />,
    <Step10 key="step10" />,
    <Step11 key="step11" />,
    <Step12 key="step12" />,
    <Step13 key="step13" />
    // <OpenSolarStep key="opensolar" />
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [prevActiveStep, setPrevActiveStep] = useState(0);

  useEffect(() => {
    setPrevActiveStep(activeStep);
  }, [activeStep]);

  return (
    <StepperContext.Provider value={{ stepsArray, activeStep, setActiveStep, prevActiveStep }}>
      {children}
    </StepperContext.Provider>
  );
};
