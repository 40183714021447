import React, { useContext, useEffect, useState } from 'react';
import bgShape8 from '../../../assets/images/bg_shape_8.png';
import { UtilityContext } from '../../../provider/utility.provider';
import { SolarDataContext } from '../../../provider/solarData.provider';
import { annualEnergyUsage, calculateSavings } from '../../../utils/utils';
import TotalSaving from '../../Charts/TotalSaving';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import addYears from 'date-fns/addYears';

const Step9 = () => {
  const { utilityRateHistoryData, utilityRateHistoricalAnnualData } = useContext(UtilityContext);
  const { solarInquiryData, solarConfiguration } = useContext(SolarDataContext);

  const INFLATION_RATE_PERCENTAGE =
    solarConfiguration && parseFloat(solarConfiguration.inflationRate); // increase by 3% per year
  const SOLAR_DEGRADATION_FACTOR_PERCENTAGE =
    solarConfiguration && parseFloat(solarConfiguration.solarDegradationFactor); // decrease 0.05% per year
  // const TOTAL_YEARS = parseInt(solarConfiguration.termInYears);
  const TOTAL_YEARS = 30;

  const [savingCalculations, setSavingCalculations] = useState(null);
  const [annualUtilityCost, setAnnualUtilityCost] = useState(null);

  useEffect(() => {
    console.log('utilityRateHistoricalAnnualData :', utilityRateHistoricalAnnualData);
  }, []);

  useEffect(() => {
    console.log(utilityRateHistoryData);
    if (solarConfiguration && solarInquiryData && solarInquiryData.customerAddress) {
      const savingCalculations = calculateSavings(
        SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
        INFLATION_RATE_PERCENTAGE,
        TOTAL_YEARS,
        solarInquiryData.customerAddress.electricityCost,
        annualEnergyUsage(
          solarInquiryData.customerAddress.electricityCost,
          solarInquiryData.customerAddress.avgMonthlyElectricityCost
        )
      );
      console.log('savingCalculations :', savingCalculations);
      setSavingCalculations(savingCalculations);

      setAnnualUtilityCost(solarInquiryData.customerAddress.avgMonthlyElectricityCost * 12);
    }
  }, [utilityRateHistoryData, solarConfiguration]);

  const labels = eachYearOfInterval({
    start: Date.now(),
    end: addYears(Date.now(), TOTAL_YEARS - 1)
  });

  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={bgShape8} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary ">Cost savings</p>
      </div>

      <div className="flex flex-col gap-4 justify-between relative w-full">
        <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-[92px] gap-6">
          <div className="bg-quaternary rounded-md px-[16px] py-[12px]">
            <p className="text-primary text-[14px] leading-[24px]">
              Do nothing cost: utility cost for next 30 years
            </p>
            <div className="my-[10px] flex justify-center items-center">
              <p className="text-primary text-[57px] leading-[64px]">
                {solarInquiryData && solarInquiryData.utility.costOfNothing30Years
                  ? `${
                      parseInt(solarInquiryData.utility.costOfNothing30Years) < 0
                        ? `-$${(
                            parseInt(solarInquiryData.utility.costOfNothing30Years) * -1
                          ).toLocaleString('en-US')}`
                        : `$${parseInt(
                            solarInquiryData.utility.costOfNothing30Years
                          ).toLocaleString('en-US')}`
                    }`
                  : `$0`}
              </p>
            </div>
          </div>
          <div className="bg-primary rounded-md px-[16px] py-[12px]">
            <p className="text-white text-[14px] leading-[24px]">
              Energy savings: 30 year total savings with solar
            </p>
            <div className="my-[10px] flex justify-center items-center">
              <p className="text-secondary text-[57px] leading-[64px]">
                $
                {savingCalculations
                  ? parseInt(savingCalculations.totalSavings.toFixed(0)).toLocaleString('en-US')
                  : 0}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white p-[16px] w-full border-[1px] border-solid border-primary min-h-[340px] sm:mb-0 mb-[72px]">
          <div className=" text-center max-h-[442px] h-[307px] text-primary flex items-center justify-center">
            {annualUtilityCost ? (
              <>
                <TotalSaving
                  labels={labels}
                  datasets={[
                    {
                      label: 'Utility Cost',
                      data: labels.map((x, i) => ({
                        x,
                        y: annualUtilityCost * 1.03 ** i
                      })),
                      fill: 'origin',
                      borderColor: 'rgba(75,192,192,1)',
                      pointBackgroundColor: 'rgba(75,192,192,1)',
                      backgroundColor: 'rgba(75,192,192,0.2)'
                    },
                    {
                      label: 'Solar Cost',
                      data: labels.map((x, i) => ({
                        x,
                        y: i < 20 ? 1800 : 0
                      }))
                    },
                    {
                      label: 'Solar Cost After Incentives',
                      data: labels.map((x, i) => ({
                        x,
                        y: i < 20 ? 1080 : 0
                      })),
                      borderColor: '#786CD0',
                      pointBackgroundColor: '#786CD0',
                      backgroundColor: '#786CD0'
                    }
                  ]}
                />
                {/* <TotalSaving
                  labels={labels}
                  datasets={[
                    {
                      label: 'Utility Cost',
                      data: labels.map((x, i) => ({
                        x,
                        y: annualUtilityCost * 1.03 ** i
                      })),
                      fill: 'origin',
                      borderColor: 'rgba(75,192,192,1)',
                      pointBackgroundColor: 'rgba(75,192,192,1)',
                      backgroundColor: 'rgba(75,192,192,0.2)'
                    }
                  ]}
                /> */}
                {/* <TotalSaving
                  labels={labels}
                  datasets={[
                    {
                      label: 'Solar Cost',
                      data: labels.map((x, i) => ({
                        x,
                        y: i < 20 ? 1800 : 0
                      }))
                    }
                  ]}
                /> */}
                {/* <TotalSaving
                  labels={labels}
                  datasets={[
                    {
                      label: 'Solar Cost After Incentives',
                      data: labels.map((x, i) => ({
                        x,
                        y: i < 20 ? 1080 : 0
                      })),
                      borderColor: '#786CD0',
                      pointBackgroundColor: '#786CD0',
                      backgroundColor: '#786CD0'
                    }
                  ]}
                /> */}
              </>
            ) : (
              <span>No data Found</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step9;
