import React from 'react';
import styles from './TimelineModal.module.scss';
import CrossIcon from '../../../../resources/icons/CrossIcon';
import Button from '../../../Button/Button';
import { MdArrowForward } from 'react-icons/md';
import ArrowRightIcon from '../../../../resources/icons/ArrowRightIcon';

const TimelineModal = ({
  step,
  title,
  time,
  children,
  isModalOpen,
  toggleModal,
  hasNext,
  onNext
}) => {
  const handleBackdropClick = (event) => {
    // Prevent the click event from propagating to the modal content
    event.stopPropagation();

    // Close the modal
    // toggleModal();
  };
  return (
    <div
      className={`${styles.modalWrapper} ${isModalOpen ? styles.open : ''}`}
      onClick={handleBackdropClick}>
      <div className={styles.modal}>
        <div className={styles.head}>
          <div className={styles.titleContainer}>
            <span className={styles.step}>{step}</span>
            <span className={styles.title}>{title}</span>
            <span className={styles.time}>{time}</span>
          </div>
          <button onClick={toggleModal}>
            <CrossIcon />
          </button>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <Button
            onClick={() => onNext()}
            size="large"
            icon={
              hasNext && (
                <MdArrowForward
                  size={24}
                  className="!fill-[#ffffff] absolute right-[24px] top-[15px]"
                />
              )
            }
            className="!py-[18px]"
            iconPosition="right"
            theme="primary">
            <p className="!min-w-[132px]">{hasNext ? ' Next Step' : 'Done'}</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TimelineModal;
