import React from 'react';
import SmallCard from '../../Card/SmallCard/SmallCard';
import TestImage from '../../../assets/images/bg-wheel.png';
import bgShape from '../../../assets/images/neo_geo_shapes_set.png';
import bgShape2 from '../../../assets/images/neo_geo_shapes_set_5.png';
import bgShape3 from '../../../assets/images/neo_geo_shapes_set_4.png';
import bgShape4 from '../../../assets/images/neo_geo_shapes_set_3.png';
import bgShape5 from '../../../assets/images/neo_geo_shapes_set_2.png';
import bgShape6 from '../../../assets/images/neo_geo_shapes_set_1.png';

const Step2 = () => {
  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden pr-8">
        <img src={TestImage} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <p className="text-[45px] leading-[52px] text-primary mb-[40px]">Introduction to Daylight</p>
      <div className="grid lg:grid-cols-3 lg:gap-4 md:grid-cols-2 md:gap-2 grid-cols-1 gap-2 sm:mb-0 mb-[72px]">
        <SmallCard
          title={'Lowest prices in the nation'}
          description={
            'We offer the lowest prices available because we recognize you as a long term partner.'
          }
          bgImg={bgShape}
        />
        <SmallCard
          title={'Industry leading insurance & warranties'}
          description={
            'All of our equipment comes with a 25-year manufacturer warranty. We additionally insure your energy upgrades beyond the customary manufacturer warranties.'
          }
          bgImg={bgShape2}
        />
        <SmallCard
          title={'Energy reimagined with our mobile app'}
          description={
            'The Daylight app gives you full control over your energy devices, insights into your energy data, and additional services like custom energy rates.'
          }
          bgImg={bgShape3}
        />
        <SmallCard
          title={'Community powered'}
          description={
            'What’s better than the best value in the industry? The ability to earn. You can enroll your devices in our Community Energy Network to support the energy grid, reducing the need for expensive and dirty peaker power plants and earning you extra cash.'
          }
          bgImg={bgShape4}
        />
        <SmallCard
          title={'Tier-1 equipment only'}
          description={
            'We only use Tier-1 equipment. Premium Black-on-Black and Enphase IQ8 Microinverters with 25+year manufacturer warranties.'
          }
          bgImg={bgShape5}
        />
        <SmallCard
          title={'Unparalleled Energy Expertise'}
          description={
            'Our team has designed power plants, managed utility power supplies, and done long term planning for grid resiliency. We’re backed by great investors like Lerer Hippeau and VaynerFund, and even greater community.'
          }
          bgImg={bgShape6}
        />
      </div>
    </div>
  );
};

export default Step2;
