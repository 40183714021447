import { createContext, useEffect, useState } from 'react';

export const ModalContext = createContext();

const defaultObject = {
  title: '',
  content: '',
  closeButtonText: 'Close',
  redirectTo: () => {}
};

export const ModalProvider = ({ children }) => {
  const [isBackdropClickDisable, setIsBackdropClickDisable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalParams, setModalParams] = useState(defaultObject);
  const [isCloseButton, setIsCloseButton] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    modalParams.redirectTo && modalParams.redirectTo();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        toggleModal,
        modalParams,
        setModalParams,
        isCloseButton,
        setIsCloseButton,
        openModal,
        closeModal,
        isBackdropClickDisable,
        setIsBackdropClickDisable
      }}>
      {children}
    </ModalContext.Provider>
  );
};
