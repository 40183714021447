import { createContext, useState } from 'react';

export const SolarDataContext = createContext();

const allStepsData = {
  id: null,
  customerAddress: null,
  customerName: 'Name',
  createdBy: 'CONCIERGE NAME',
  utility: {
    avgMonthlyElectricBill: 172,
    avgAnnualElectricBill: 2064,
    estimatedAnnualIncrease: 3.5,
    costOfNothing30Years: -61920,
    monthlyConsumption: {
      mode: 'energy',
      kwh: [
        {
          x: 'Sep-22',
          y: 626
        },
        {
          x: 'Oct-22',
          y: 1311
        },
        {
          x: 'Nov-22',
          y: 663
        },
        {
          x: 'Dec-22',
          y: 757
        },
        {
          x: 'Jan-23',
          y: 887
        },
        {
          x: 'Feb-23',
          y: 1183
        },
        {
          x: 'Mar-23',
          y: 1144
        },
        {
          x: 'Apr-23',
          y: 600
        },
        {
          x: 'May-23',
          y: 686
        },
        {
          x: 'Jun-23',
          y: 685
        },
        {
          x: 'Jul-23',
          y: 819
        },
        {
          x: 'Aug-23',
          y: 1224
        }
      ],
      cost: [
        {
          x: 'Sep-22',
          y: 61.78
        },
        {
          x: 'Oct-22',
          y: 142.1
        },
        {
          x: 'Nov-22',
          y: 65.67
        },
        {
          x: 'Dec-22',
          y: 75.78
        },
        {
          x: 'Jan-23',
          y: 89.68
        },
        {
          x: 'Feb-23',
          y: 125.78
        },
        {
          x: 'Mar-23',
          y: 120.82
        },
        {
          x: 'Apr-23',
          y: 59
        },
        {
          x: 'May-23',
          y: 68.19
        },
        {
          x: 'Jun-23',
          y: 68.01
        },
        {
          x: 'Jul-23',
          y: 82.41
        },
        {
          x: 'Aug-23',
          y: 131.02
        }
      ]
    },
    totalDue: -172
  },
  systemSize: 9.6,
  panelType: 'Silfab All-Black',
  Inverter: 'Enphase iQ8',
  solarOffSetAmount: '100%',
  totalSavingOver30Years: 53000,
  solarHomeImgUrl: null,
  newMonthEnergyPayment: 179,
  monthlyDaylighCashback: 85,
  newMonthPaymentCashback: 94,
  cashPriceSummery: 9.6,
  year1SystemProduction: 12383,
  numberOfPanels: 23,
  grossSystemCost: 38935,
  incentives: 10000,
  daylightCashback: 38935,
  netSystemCashCost: 26962,
  batteryQuantity: 0,
  batteryBrand: 'FranklinWH 13.6 kWh Battery',
  batteryGrossInstalledCost: 14000,
  batteryFedralIncentives: 4200,
  batteryLocalAndStateIncentives: 'Varies',
  batteryDaylightCashback: 1100,
  batteryNetCost: 8700,
  solarProposalPdf: null
};

export const SolarDataProvider = ({ children }) => {
  const [solarInquiryData, setSolarInquiryData] = useState(allStepsData);
  const [solarConfiguration, setSolarConfiguration] = useState(null);
  const [authToken, setAuthToken] = useState();

  const updateSolarInquiryData = (updatedSolarInquiryData) => {
    const nextSolarInquiryData = { ...solarInquiryData, ...updatedSolarInquiryData };
    setSolarInquiryData(nextSolarInquiryData);
  };

  return (
    <SolarDataContext.Provider
      value={{
        solarInquiryData,
        setSolarInquiryData,
        solarConfiguration,
        setSolarConfiguration,
        updateSolarInquiryData,
        authToken,
        setAuthToken
      }}>
      {children}
    </SolarDataContext.Provider>
  );
};
