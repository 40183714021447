import React from 'react';
import TimelineGraph from '../../Timeline Graph/TimelineGraph';

const Step12 = () => {
  return (
    <div className="pt-[40px] pb-[100px]">
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">Next steps</p>
      </div>

      <div className="w-full">
        <TimelineGraph />
      </div>
    </div>
  );
};

export default Step12;
