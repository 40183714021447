import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StepperProvider } from './provider/stepper.provider';
import { ModalProvider } from './provider/modal.provider';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { UtilityProvider } from './provider/utility.provider';
import { SolarDataProvider } from './provider/solarData.provider';
import { LeadProvider } from './provider/lead.provider';
import TagManager from 'react-gtm-module';

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_QL_BASE_URL,
  cache: new InMemoryCache()
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  dataLayerName: 'PageDataLayer'
};
const isProduction = CURRENT_ENV === 'production';
if (isProduction) {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ModalProvider>
          <StepperProvider>
            <UtilityProvider>
              <SolarDataProvider>
                <LeadProvider>
                  <App />
                </LeadProvider>
              </SolarDataProvider>
            </UtilityProvider>
          </StepperProvider>
        </ModalProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
