import React, { Fragment, useEffect, useState } from 'react';
import bgShape10 from '../../../assets/images/bg_shape_10.png';
import NetMeteringIcon from '../../../resources/icons/NetMeteringIcon';
import PlusCircularIcon from '../../../resources/icons/PlusCircularIcon';
import CameraIcon from '../../../resources/icons/CameraIcon';
import LeafShieldIcon from '../../../resources/icons/LeafShieldIcon';
import SolarPanelChargeIcon from '../../../resources/icons/SolarPanelChargeIcon';
import SolarPowerIcon from '../../../resources/icons/SolarPowerIcon';
import WrenchIcon from '../../../resources/icons/WrenchIcon';
import StorageBoxIcon from '../../../resources/icons/StorageBoxIcon';
import MoneyDonationIcon from '../../../resources/icons/MoneyDonationIcon';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Step13 = () => {
  const [activeFAQ, setActiveFAQ] = useState('net-metering');
  const FAQs = [
    {
      id: 'net-metering',
      title: 'Net Metering',
      icon: <NetMeteringIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              Will I still have a utility bill after I go solar?
            </div>
            <div className="text-[16px] leading-[24px] mb-4">
              Yes, most of our customers will continue to receive an electricity bill from their
              utility company, though it will be significantly reduced. This is because there are
              times when your home may use more power than your solar system produces. So, in
              addition to your solar loan payment, you&apos;ll also have a smaller bill for that
              extra electricity from your utility provider.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              But here&apos;s the good news! during the times when your solar system creates more
              power than your household uses, your utility provider will give you credits for that
              extra electricity at the retail rate. This process is part of what we call &apos;net
              metering&apos;. Think of it like a savings account for your surplus solar power - you
              &apos;deposit&apos; when you produce more and &apos;withdraw&apos; when you need
              extra. These credits will reduce or eliminate the cost of the energy you purchase from
              your utility when your consumption exceeds solar production.
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'upfront-costs',
      title: 'Upfront Costs',
      icon: <CameraIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              How much is the upfront cost?
            </div>
            <div className="text-[16px] leading-[24px]">
              Big: ZERO! Your monthly payment will begin after the system is installed.
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'insurance-warranty',
      title: 'Insurance & Warranty',
      icon: <LeafShieldIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              What if my system stops producing?
            </div>
            <div className="text-[16px] leading-[24px]">
              Every system comes with a 25-year manufacturer guaranteed warranty. If we detect any
              production issues coming from your system, we will immediately dispatch a crew to
              inspect the system. If there are any issues we will contact the manufacturer on your
              behalf.
            </div>
          </li>
          <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              What if something happens to my roof during installation?
            </div>
            <div className="text-[16px] leading-[24px]">
              Nothing should happen to your roof during installation. However, if your roof is
              damaged, Daylight has a [$2 million] umbrella insurance policy to protect you at no
              additional cost.
            </div>
          </li>
          <li className="py-[16px] text-[16px] leading-[24px] text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              Does financing solar place a lien on my home?
            </div>
            <div className="text-[16px] leading-[24px]">
              <p className=" mb-4">
                {`If you decide to finance or lease your solar panels, the finance or leasing
        company will place a claim, known as a 'lien', on the solar equipment itself.
        They make this official by filing a form called a 'UCC-1', or Uniform Commercial
        Code Financing Statement, as well as a county fixture filing.`}
              </p>
              <p className=" mb-4">
                {`Now, it's important to understand that this lien is not against your home
        itself. It doesn't affect your mortgage or home ownership in any way. What it
        does do is appear as a 'UCC-1 fixture filing' on the title of your property.`}
              </p>
              <p className=" mb-4">
                {`Why does the finance company do this? It's a way of ensuring that if, for any reason, your home was to be foreclosed, the finance company would still have a claim to the solar system. The UCC-1 filing prevents your mortgage lender from taking ownership of the solar system if your home is foreclosed. So, it's simply a way for the finance or leasing company to protect their investment in the solar system.`}
              </p>
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'installation',
      title: 'Installation',
      icon: <SolarPanelChargeIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              How long does installation take?
            </div>
            <div className="text-[16px] leading-[24px]">
              Once your system is permitted and approved, the actual installation typically takes
              one day.
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'power-outages',
      title: 'Power Outages',
      icon: <SolarPowerIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              Will a solar system run my house in the event of a power outage?
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              A standalone solar system, also known as a grid-tied solar system, is designed to
              supply power to your home and to feed excess electricity back into the electric grid.
              During the day, your solar panels generate electricity which powers your home. If they
              produce more power than you use, that extra energy is sent back to the grid.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              However, for safety reasons, these grid-tied systems are designed to automatically
              shut down in the event of a power outage. This feature, known as
              &ldquo;anti-islanding,&rdquo; is important to protect utility workers who might be
              fixing power lines during an outage. If your solar system continued to feed
              electricity into the grid, it could potentially cause harm to those workers.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              So, during a power outage, even if it&apos;s a sunny day and your solar panels are
              generating electricity, a grid-tied system will shut off and your home will be without
              power.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              The good news is that there&apos;s a way around this: adding a backup battery to your
              solar system. With a battery, when a power outage occurs, your solar system would
              disconnect from the grid (keeping utility workers safe), but instead of shutting down
              completely, it would continue to power your home by feeding stored electricity from
              the battery.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              Not only does this give you electricity during an outage, but it also allows you to
              use your solar power after the sun goes down. By storing excess power in your battery
              during the day, you can use that stored power in the evening or at night, further
              reducing your reliance on the grid.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              So, while a standalone solar system won&apos;t keep your home powered during an
              outage, adding a battery backup allows you to use solar power day and night, and keeps
              your lights on when the power goes out. You can easily add a battery to your solar
              system with Daylight.
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'maintenance',
      title: 'Maintenance',
      icon: <WrenchIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              How long will my panels last?
            </div>
            <div className="text-[16px] leading-[24px]">
              Solar panels are designed for durability and long-lasting performance, typically
              providing you with solar power for approximately 25-30 years. To give you peace of
              mind, all of our systems come with manufacturer warranties that match their lifespan.
            </div>
          </li>
          <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              What kind of maintenance do my solar panels need?
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              One of the great advantages of solar systems is that they&apos;re relatively
              low-maintenance. The absence of moving parts in most solar panels minimizes the chance
              of any mechanical failure, which reduces the amount of ongoing care required.
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              If we detect any maintenance issues with your solar panels, we will dispatch a team to
              provide the necessary maintenance at no cost.
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'moving',
      title: 'Moving',
      icon: <StorageBoxIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              What happens if I move?
            </div>
            <div className="text-[16px] leading-[24px]">
              There are two options if you move:
              <ol>
                <li className="ml-2 my-[8px]">
                  <span className="font-aeonik-bold">1) Pay off the balance</span>
                  <div className="ml-4">
                    In the vast majority of cases homeowners choose to pay off any remaining balance
                    through the sale of their home. Remember, solar typically increases the value
                    and attractiveness of your home.
                  </div>
                </li>
                <li className="ml-2 my-[8px]">
                  <span className="font-aeonik-bold">2) Transfer the loan</span>
                  <div className="ml-4">
                    If the new homeowner wishes to assume the remaining payments, he/she must apply
                    to qualify. If the new homeowner is approved, then he/she assumes full
                    responsibility of the financial agreement.
                  </div>
                </li>
              </ol>
            </div>
          </li>
        </ul>
      )
    },
    {
      id: 'tax-incentives',
      title: 'Tax Incentives',
      icon: <MoneyDonationIcon />,
      content: (
        <ul>
          <li className="block justify-between items-center pb-[16px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              What are the federal tax incentives for solar?
            </div>
            <div className="text-[16px] leading-[24px]  mb-4">
              There is a federal tax credit for solar is a non-refundable credit that can be claimed
              on federal income taxes for a percentage of the cost of a solar photovoltaic (PV)
              system. The credit is currently 30% of the total cost of the system, including
              installation.
            </div>
            <div className="text-[16px] leading-[24px] mb-4">
              Note that this is a tax credit, not a tax deduction. An income-tax credit is a
              reduction of your taxes while an income-tax deduction is a reduction of your taxable
              income. For example, if your total federal income-tax for 2016 (before subtracting
              estimated tax payments and withholding) would have been $16,000, but you got a $8,000
              tax credit from purchasing the example solar, photo-voltaic (PV) system, your tax
              would only be $8,000; the credit comes off the tax. That’s a good thing - tax credits
              are significantly more valuable than tax deductions!
            </div>
            <div className="text-[16px] leading-[24px] mb-4">
              There is no maximum amount that can be claimed, so the credit can be significant for
              homeowners. For example, a homeowner who installs a $20,000 solar system would be
              eligible for a $6,000 tax credit.
            </div>
            <div className="text-[16px] leading-[24px] mb-4">
              The credit can be claimed on the year in which the solar system is installed. So, if
              you install a solar system in 2023, you would claim the credit on your 2023 tax
              return.
            </div>
            <div className="text-[16px] leading-[24px] mb-4">
              Typically, homeowners use the tax credit refund to “buy down” the cost of their
              system, further reducing their monthly payments.
            </div>
          </li>
          <li className="py-[16px] text-[16px] leading-[24px] border-b-[1px] border-solid border-primary text-primary">
            <div className="text-[16px] font-aeonik-bold leading-[24px] mb-[16px]">
              Are there local incentives?
            </div>
            <div className="text-[16px] leading-[24px] mb-4">
              Yes! Depending on your state, there may be state or local incentives for solar.
            </div>
          </li>
        </ul>
      )
    }
  ];

  return (
    <div className="pt-[40px] pb-[100px]">
      <div className="h-[80vh] absolute overflow-hidden ">
        <img src={bgShape10} className="xl:w-[50%] lg:w-[80%] w-[100%] ml-auto" alt="" />
      </div>
      <div>
        <p className="text-[45px] leading-[52px] text-primary mb-[40px]">FAQ </p>
      </div>

      <div className="flex flex-col gap-1 justify-between relative w-full">
        <div className="w-full flex flex-col lg:flex-row justify-center gap-[24px]">
          <div className="lg:w-[20%] w-full sm:mb-0 mb-[72px]">
            <div className=" text-primary">
              <ul>
                {FAQs.map((faq) => (
                  <Fragment key={faq.id}>
                    <li
                      className="[&:not(:last-child)]:border-b-[1px] border-solid border-primary py-[16px] cursor-pointer"
                      onClick={() => setActiveFAQ(faq.id)}>
                      <div
                        className={`flex items-center gap-[16px] active:bg-secondary ${
                          activeFAQ === faq.id ? 'bg-secondary' : ''
                        }`}>
                        {faq.icon}{' '}
                        <div className="flex gap-2 justify-between items-center w-full">
                          <span>{faq.title}</span>{' '}
                          {activeFAQ !== faq.id ? (
                            <PlusCircularIcon />
                          ) : (
                            <>
                              <AiOutlineCloseCircle size={24} className="!fill-primary" />
                            </>
                          )}
                        </div>
                      </div>
                    </li>

                    <div className="lg:hidden block">
                      <div
                        className={` ${
                          activeFAQ === faq.id
                            ? 'block mt-5  max-h-[300px] overflow-auto'
                            : 'hidden'
                        }`}>
                        {faq.content}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </ul>
            </div>
          </div>

          <div className="lg:block hidden border-[1px] border-solid border-primary p-[16px] lg:w-[80%] w-full bg-white mx-0 max-h-[456px] overflow-y-auto">
            {FAQs.map((faq) => (
              <div key={faq.id} className={` ${activeFAQ === faq.id ? 'block' : 'hidden'}`}>
                {faq.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step13;
