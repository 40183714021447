import React from 'react';

const SolarPowerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_2817_30587)">
        <path
          d="M12 18.71C15.7059 18.71 18.71 15.7059 18.71 12C18.71 8.29421 15.7059 5.29004 12 5.29004C8.29421 5.29004 5.29004 8.29421 5.29004 12C5.29004 15.7059 8.29421 18.71 12 18.71Z"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
        <path d="M12 0.5V3.38" stroke="#15049E" strokeWidth="1.92" strokeMiterlimit="10" />
        <path d="M12 20.63V23.5" stroke="#15049E" strokeWidth="1.92" strokeMiterlimit="10" />
        <path d="M23.4999 12H20.6299" stroke="#15049E" strokeWidth="1.92" strokeMiterlimit="10" />
        <path d="M3.38 12H0.5" stroke="#15049E" strokeWidth="1.92" strokeMiterlimit="10" />
        <path
          d="M20.1301 3.87L18.1001 5.9"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
        <path
          d="M5.90012 18.0996L3.87012 20.1296"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
        <path
          d="M20.1301 20.1296L18.1001 18.0996"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
        <path
          d="M5.90012 5.9L3.87012 3.87"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
        <path
          d="M12 11.04L11.28 12H12V11.04Z"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
        <path
          d="M12 12.96L12.72 12H12V12.96Z"
          stroke="#15049E"
          strokeWidth="1.92"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2817_30587">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolarPowerIcon;
