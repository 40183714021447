export const sidebarItems = [
  { title: 'Introduction to Daylight', step: 1 },
  { title: 'Your current energy profile', step: 2 },
  { title: 'Energy bill increases in your area', step: 3 },
  { title: 'Why Solar', step: 4 },
  { title: 'Why Now?', step: 5 },
  { title: 'How Solar Works', step: 6 },
  { title: 'System Design', step: 7 },
  { title: 'Cost Savings', step: 8 },
  { title: 'Solar system quote', step: 9 },
  { title: 'Energy independence', step: 10 },
  { title: 'Payment Terms', step: 11 },
  { title: 'FAQ', step: 12 }
  // { title: 'System Details', step: 13 }
];
