import React, { useEffect, useState } from 'react';
import './SVGPopup.scss';
import CloseCircularIcon from '../../../../../resources/icons/CloseCircularIcon';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const SVGPopup = ({ x, y, title, content, active, onClose, height = '130px', width = '278px' }) => {
  return (
    <foreignObject
      className={`popup-container ${active ? 'active' : ''}`}
      x={x}
      y={y}
      width={width}
      height={height}>
      <div className="popup">
        <div className="header">
          {title}
          <span className="icon" onClick={onClose}>
            {/* <CloseCircularIcon /> */}
            <AiOutlineCloseCircle size={24} className="!fill-primary" />
          </span>
        </div>

        <div className="content">{content}</div>
      </div>
    </foreignObject>
  );
};

export default SVGPopup;
