import React from 'react';

const MaximizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="2" fill="white" />
      <path d="M11.5489 8.45107L15.6685 4.3315L15.7065 4.29346" fill="white" />
      <path d="M8.45107 11.5489L4.3315 15.6685L4.29346 15.7065" fill="white" />
      <path d="M4.3315 11.5489L4.3315 15.6685H8.45107" fill="white" />
      <path d="M15.6685 8.45107V4.3315L11.5489 4.3315" fill="white" />
      <path d="M15.7065 15.7065L15.6685 15.6685L11.5489 11.5489" fill="white" />
      <path d="M4.29346 4.29346L4.3315 4.3315L8.45107 8.45107" fill="white" />
      <path d="M8.45107 4.3315L4.3315 4.3315L4.3315 8.45107" fill="white" />
      <path d="M11.5489 15.6685H15.6685V11.5489" fill="white" />
      <path
        d="M11.5489 8.45107L15.6685 4.3315M15.6685 4.3315L15.7065 4.29346M15.6685 4.3315V8.45107M15.6685 4.3315L11.5489 4.3315M8.45107 11.5489L4.3315 15.6685M4.3315 15.6685L4.29346 15.7065M4.3315 15.6685L4.3315 11.5489M4.3315 15.6685H8.45107M15.7065 15.7065L15.6685 15.6685M15.6685 15.6685L11.5489 11.5489M15.6685 15.6685H11.5489M15.6685 15.6685V11.5489M4.29346 4.29346L4.3315 4.3315M4.3315 4.3315L8.45107 8.45107M4.3315 4.3315L8.45107 4.3315M4.3315 4.3315L4.3315 8.45107"
        stroke="#15049E"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default MaximizeIcon;
