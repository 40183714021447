export const FamilyType = {
  Single: 'Single',
  Multi: 'Multi'
};

export const LeadType = {
  SOLAR: 'SOLAR',
  SALES: 'SALES',
  MARKETING: 'MARKETING',
  ONBOARDING: 'ONBOARDING',
  MINI_WEBSITE: 'MINI_WEBSITE',
  APPLICATION: 'APPLICATION'
};

export const PropertyType = {
  Rent: 'Rent',
  Own: 'Own'
};

export const UtilityStatusType = {
  Pending: 'Pending',
  Failed: 'Failed',
  Success: 'Success'
};
