import React from 'react';
import './SmallCard.scss';

import addIcon from '../../../assets/icons/add.png';

const SmallCard = ({ title = '', description = '', bgImg }) => {
  return (
    <div className="card border-solid border-primary rounded-lg min-h-[219px] overflow-hidden  justify-center items-center flex">
      <div className="front bg-primary shadow-lg">
        {bgImg && <img src={bgImg} className="card-bg-image" alt="" />}
        <div className="front-header-img">
          <img src={addIcon} alt="" />
        </div>
        <h2 className="text-[35px] text-white leading-[35px] text-center max-w-[230px] font-aeonik-light">
          {title}
        </h2>
      </div>
      <div className="back shadow-3xl">
        <div>
          <p className="text-[16px] text-primary leading-[24px] text-left">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SmallCard;
