import { Fragment } from 'react';

export function ButtonSelect(props) {
  return (
    <div className="inline-flex bg-primary text-primary rounded-lg px-[10px] py-[8px]">
      {props.items.map((item, i) => (
        <Fragment key={i}>
          <button
            className={`px-4 py-1 ${
              props.selectedValue === item.value
                ? 'bg-white rounded-full text-primary'
                : 'text-white'
            }`}
            key={item.label}
            value={item.value}
            onClick={() => props.onChange(item.value)}>
            {item.label}
          </button>
          {i < props.items.length - 1 && <span className=""></span>}
        </Fragment>
      ))}
    </div>
  );
}
