import React from 'react';

const IdeaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="67" viewBox="0 0 66 67" fill="none">
      <circle cx="33" cy="33.5" r="33" fill="#15049E" />
      <path
        d="M28.2522 41.6939C28.2572 39.7107 27.5014 37.8012 26.1406 36.3586C25.0579 35.2315 24.2686 33.8557 23.8419 32.3522C23.4152 30.8487 23.3642 29.2634 23.6934 27.7356C24.0225 26.2078 24.7218 24.7841 25.7297 23.5897C26.7376 22.3952 28.0234 21.4665 29.4741 20.8852C30.9248 20.3038 32.4961 20.0875 34.05 20.2552C35.6038 20.423 37.0927 20.9697 38.3859 21.8474C39.6791 22.7251 40.737 23.9068 41.4667 25.2889C42.1964 26.671 42.5757 28.2111 42.5711 29.7739C42.5734 32.2472 41.6094 34.6235 39.8847 36.3961C38.5276 37.824 37.7792 39.724 37.7981 41.6939M28.2522 41.6939H37.7981M28.2522 41.6939L28.2522 44.0803C28.2522 44.7133 28.5036 45.3203 28.9512 45.7678C29.3987 46.2154 30.0057 46.4668 30.6387 46.4668H35.4116C36.0446 46.4668 36.6516 46.2154 37.0991 45.7678C37.5467 45.3203 37.7981 44.7133 37.7981 44.0803V41.6939M18.7188 29.7739H21.0927M22.2922 20.228H24.6787M22.2922 39.3074H24.6787M47.3316 29.7739H44.9576M43.7581 39.3074H41.3716M43.7581 20.228H41.3716M33.0252 28.5745L30.6387 32.1479H33.0252V28.5745ZM33.0252 34.5469L35.4116 30.9609H33.0252V34.5469Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default IdeaIcon;
